import React from 'react';
import type { ValidationError } from 'yup';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RoutesEnum } from 'shared/utils/route';
import {
  getSectionErrorsYup,
  getSectionProgress,
  getPageProgress,
} from 'shared/utils/calculateProgress';
import { useInspection } from 'shared/components/InspectionContext';
import { CarServiceSchema } from '../car-service.schema';
import {
  SERVICE_BOOK_AVAILABLE_PAPER,
  SERVICE_BOOK_APPOINTMENTS_ALL,
  SERVICE_BOOK_APPOINTMENTS_SOME,
} from '../constants';
import type { ServiceForm, AccidentForm } from '../types';

const sections = [
  {
    sectionName: RoutesEnum.SERVICE_AND_DOCUMENTS_SERVICE,
    prefix: 'service',
    requiredFieldsNb: (isPdfInspection?: boolean) => (isPdfInspection ? 3 : 6),
    isOptional: () => {
      return false;
    },
  },
  {
    sectionName: RoutesEnum.SERVICE_AND_DOCUMENTS_ACCIDENTS,
    prefix: 'accident',
    requiredFieldsNb: () => 1,
    isOptional: () => {
      return false;
    },
  },
  {
    sectionName: RoutesEnum.SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS,
    prefix: '',
    requiredFieldsNb: (condition?: boolean) => (condition ? 2 : 0),
    isOptional: (isPdfInspection?: boolean) => !isPdfInspection,
  },
];

const getServiceFieldsNb = ({
  service,
  isPdfInspection,
  requiredFieldsNb,
}: {
  service: ServiceForm;
  isPdfInspection: boolean;
  requiredFieldsNb: (_: boolean) => number;
}) => {
  let requiredFields = requiredFieldsNb(isPdfInspection);
  if (service?.driveBeltChanged === true) {
    if (isPdfInspection) {
      requiredFields += 1;
    }
    requiredFields += 2;
  }
  if (service?.isOriginalEngine === false) {
    requiredFields += 1;
  }
  if (service?.serviceBook === SERVICE_BOOK_AVAILABLE_PAPER) {
    requiredFields += 1;
    if (isPdfInspection) {
      requiredFields += 1;
    }
  }
  if (service?.isRoadworthy === false) {
    requiredFields += 1;
  }
  if (
    (
      [SERVICE_BOOK_APPOINTMENTS_SOME, SERVICE_BOOK_APPOINTMENTS_ALL] as (
        | string
        | null
      )[]
    ).includes(service?.serviceBookAppointments)
  ) {
    requiredFields += 2;
    if (isPdfInspection) {
      requiredFields += 2;
    }
  }
  return requiredFields;
};

const getAccidentFieldsNb = ({
  accident,
  isPdfInspection,
  requiredFieldsNb,
}: {
  accident: AccidentForm;
  isPdfInspection: boolean;
  requiredFieldsNb: (_: boolean) => number;
}) => {
  let requiredFields = requiredFieldsNb(isPdfInspection);
  if (accident?.hasHadAccident === true) {
    requiredFields += 1;
    if (accident?.wasRepaired === true) {
      requiredFields += 1;
    }
  }
  return requiredFields;
};

const useProgress = () => {
  const { setProgress, isPdfInspection } = useInspection();
  const { translations } = useTranslation();

  const getServiceDocumentsProgress = React.useCallback(
    (formValues) => {
      const progressArray: number[] = [];
      const { service, accident, companyId, inspectionReport } = formValues;
      const validationSchema = CarServiceSchema(
        translations,
        false,
        isPdfInspection,
      );

      validationSchema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          const sectionsProgress = sections.reduce((acc, { sectionName }) => {
            acc[sectionName] = 100;

            return acc;
          }, {} as Record<typeof sections[number]['sectionName'], number>);

          setProgress({
            [RoutesEnum.SERVICE_AND_DOCUMENTS]: 100,
            ...sectionsProgress,
          });
        })
        .catch((e) => {
          const errors: ReadonlyArray<ValidationError> = e.inner;
          const sectionsProgress = sections.reduce(
            (acc, { sectionName, prefix, requiredFieldsNb, isOptional }) => {
              let totalFieldsNb = 0;
              let sectionErrors = {};
              switch (sectionName) {
                case RoutesEnum.SERVICE_AND_DOCUMENTS_SERVICE: {
                  totalFieldsNb = getServiceFieldsNb({
                    service,
                    requiredFieldsNb,
                    isPdfInspection,
                  });
                  sectionErrors = getSectionErrorsYup(errors, prefix);
                  break;
                }
                case RoutesEnum.SERVICE_AND_DOCUMENTS_ACCIDENTS: {
                  totalFieldsNb = getAccidentFieldsNb({
                    accident,
                    requiredFieldsNb,
                    isPdfInspection,
                  });
                  sectionErrors = getSectionErrorsYup(errors, prefix);
                  break;
                }
                case RoutesEnum.SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS: {
                  totalFieldsNb = requiredFieldsNb(
                    isPdfInspection || inspectionReport || companyId,
                  );
                  sectionErrors = errors.filter((error) =>
                    ['companyId', 'inspectionReport'].includes(
                      error?.path ?? '',
                    ),
                  );
                  break;
                }
                default: {
                  break;
                }
              }

              const progress = getSectionProgress({
                errors: sectionErrors,
                totalFieldsNb,
                isOptional: isOptional(isPdfInspection),
              });

              if (
                sectionName === RoutesEnum.SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS
              ) {
                if (
                  requiredFieldsNb(
                    isPdfInspection || inspectionReport || companyId,
                  ) > 0
                ) {
                  progressArray.push(progress);
                }
              } else {
                progressArray.push(progress);
              }

              acc[sectionName] = progress;

              return acc;
            },
            {} as Record<typeof sections[number]['sectionName'], number>,
          );

          setProgress({
            [RoutesEnum.SERVICE_AND_DOCUMENTS]: getPageProgress(progressArray),
            ...sectionsProgress,
          });
        });
    },
    [isPdfInspection, translations, setProgress],
  );

  return getServiceDocumentsProgress;
};

export { useProgress };
