import type { MutationResult } from '@apollo/client';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import {
  useUpdateCarQualityMutation,
  useUpdateCarQualityV2Mutation,
} from 'gql/graphql';
import type {
  DamageInput,
  HighlightInput,
  VehicleDamageArea,
  VehicleDamagePart,
  VehicleDamageType,
  VehicleMimeType,
  MetaPaintThicknessInput,
  VehiclePaintThicknessInput,
  UpdateCarQualityMutation,
  UpdateCarQualityV2Mutation,
} from 'gql/graphql';
import { mimeTypeToVehicleMimeType } from 'shared/utils/mimeType';
import type { ImageData, PaintThicknessVersion } from '../types';

type BuildMultipleImageMapper = (
  items: ReadonlyArray<{
    area: VehicleDamageArea | null;
    part: VehicleDamagePart | null;
    type?: VehicleDamageType | null;
    files: ReadonlyArray<ImageData | File>;
    isAccidentReason?: boolean;
  }>,
  imageUploader: ReturnType<typeof useMultipleMediaUploader>['uploadMedia'],
) => Promise<Array<DamageInput | HighlightInput>>;

type UpdateCarQuality = ({
  damages,
  isPaintThicknessChecked,
  paintThickness,
  highlights,
  optionalSchema,
}: {
  damages: Array<{
    area: VehicleDamageArea | null;
    part: VehicleDamagePart | null;
    type: VehicleDamageType | null;
    files: Array<ImageData | File>;
    isAccidentReason: boolean;
  }>;
  isPaintThicknessChecked: boolean | null;
  paintThickness: MetaPaintThicknessInput | Array<VehiclePaintThicknessInput>;
  highlights: Array<{
    area: VehicleDamageArea | null;
    part: VehicleDamagePart | null;
    files: Array<ImageData | File>;
  }>;
  optionalSchema: boolean;
}) => Promise<void>;

export function useCarQualityUpdater(
  inspectionId: number,
  stockNumber: string | null,
  paintThicknessVersion: PaintThicknessVersion,
) {
  const { uploadMedia: uploadHighlightsMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const { uploadMedia: uploadDamagesMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const [mutateV1, mutateV1Result] = useUpdateCarQualityMutation();
  const [mutateV2, mutateV2Result] = useUpdateCarQualityV2Mutation();
  const buildMultipleImageMapper: BuildMultipleImageMapper = async (
    items = [],
    imageUploader,
  ) => {
    const uploadedImages = await Promise.all(
      items.map(async (item) => {
        const previouslyUpload: Array<ImageData> = [];
        const imagesToUpload: Array<{
          part: string;
          source: 11;
          stockNumber: string;
          file: File;
        }> = [];
        Array.from(item.files ?? []).forEach((file) => {
          if (file instanceof File) {
            if (stockNumber === null) {
              throw new Error('stock number is null');
            }

            imagesToUpload.push({
              part: `${item.part}`,
              source: 11,
              stockNumber,
              file,
            });
          } else {
            previouslyUpload.push({
              ...file,
            });
          }
        });
        const uploadedImages = await imageUploader(imagesToUpload);
        const images = uploadedImages.map<{
          absoluteUrl: string;
          mimeType: VehicleMimeType;
          pointsOfInterest: never[];
        }>(
          ({
            data: {
              wkda: { url },
            },
            mimeType,
          }) => ({
            absoluteUrl: url,
            mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
            pointsOfInterest: [],
          }),
        );
        const { files, ...mutationValues } = item;
        return {
          ...mutationValues,
          images: [...previouslyUpload, ...images],
        };
      }),
    );

    return uploadedImages;
  };

  const updateCarQuality: UpdateCarQuality = async ({
    damages,
    isPaintThicknessChecked,
    paintThickness,
    highlights,
    optionalSchema,
  }) => {
    const [highlightsToUpload, damagesToUpload] = await Promise.all([
      buildMultipleImageMapper(highlights, uploadHighlightsMedia),
      buildMultipleImageMapper(damages, uploadDamagesMedia),
    ]);

    if (paintThicknessVersion === 1) {
      await mutateV1({
        variables: {
          inspectionId,
          damages: damagesToUpload,
          highlights: highlightsToUpload as Array<HighlightInput>,
          incomplete: optionalSchema,
          paintThickness: paintThickness as MetaPaintThicknessInput,
        },
      });
    } else {
      await mutateV2({
        variables: {
          inspectionId: inspectionId as number,
          damages: damagesToUpload,
          highlights: highlightsToUpload as Array<HighlightInput>,
          incomplete: optionalSchema,
          paintThickness: paintThickness as Array<VehiclePaintThicknessInput>,
          skipPaintThickness:
            isPaintThicknessChecked === null ||
            isPaintThicknessChecked === undefined,
          isPaintThicknessChecked: !!isPaintThicknessChecked,
        },
      });
    }
  };

  return [
    updateCarQuality,
    paintThicknessVersion === 1 ? mutateV1Result : mutateV2Result,
  ] as [
    UpdateCarQuality,
    MutationResult<UpdateCarQualityMutation | UpdateCarQualityV2Mutation>,
  ];
}
