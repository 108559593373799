import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { appRoutes } from 'appRoutes';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '@auto1-ui/button';
import forwoardIcon from 'assets/icons/forward.svg';
import Loader from 'shared/components/Loader';
import { InputLabel } from 'shared/components/InputLabel';
import { useInspection } from 'shared/components/InspectionContext';
import { LoadingCircle } from 'shared/components/LoaderCircle';
import { Modal } from 'shared/components/Modal';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { useTranslation } from 'shared/hooks/useTranslation';
import { formatLocaleNumber } from 'shared/utils/formatLocaleNumber';
import { useStartPrice } from '../../hooks/useStartPrice';
import { FormInputPlaceholder } from 'shared/components/FormInput/Placeholder';
import { Skeleton } from 'shared/components/Skeleton';
import { Typography } from 'shared/components/Typography';
import type { Translations } from 'shared/utils/translations';
import type { DirectAuctionForm } from '../../types';
import styles from './index.module.scss';
import {
  useEvaSubmitToDirectAuctionMutation,
  useSellerQuery,
} from 'gql/graphql';

type Props = {
  inspectionId: number;
  handleCancelClick: () => void;
  termsAccepted: boolean;
  failedReasons: number[];
  submitTracking: (
    action: string,
    payload: { expectedPrice: number; startPrice: number },
  ) => void;
};

const translatedMsgs = [
  'INCOMPLETE_INSPECTION',
  'INSPECTION_ALREADY_SUBMITTED',
];

const DirectAuctionModal: React.FC<Props> = ({
  inspectionId,
  handleCancelClick,
  termsAccepted,
  failedReasons,
  submitTracking,
}) => {
  const history = useHistory();
  const { translations } = useTranslation();
  const { setNotCompletedSteps } = useInspection();
  const formMethods = useForm<DirectAuctionForm>({
    resolver: yupResolver(
      yup.object({
        expectedPrice: yup
          .number()
          .nullable(true)
          .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
          .min(1, translations.GREATER_THAN_ZERO),
        startPrice: yup
          .number()
          .nullable(true)
          .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
          .test(
            'is-less-than-max',
            translations.START_PRICE_ERROR_MESSAGE_HIGHER_THAN_MAX,
            (value, context) => {
              const expectedPrice = context.parent.expectedPrice;
              if (!value || !expectedPrice) {
                return true;
              }
              return value <= expectedPrice;
            },
          )
          .test('is-more-than-min', (value, context) => {
            const suggestedStartPrice = context.parent.suggestedStartPrice;

            if (!value || !suggestedStartPrice) {
              return true;
            }
            if (value >= suggestedStartPrice) {
              return true;
            }
            return context.createError({
              message: `${translations.START_PRICE_ERROR_MESSAGE_LOWER_THAN_MIN} ${suggestedStartPrice}`,
              path: 'startPrice',
            });
          }),
      }),
    ),
  });

  const {
    clearErrors,
    getValues,
    setValue,
    setError,
    watch,
    register,
    unregister,
    formState: { errors: formErrors },
  } = formMethods;
  const expectedPriceValue = watch('expectedPrice');
  const startPriceValue = watch('startPrice');
  const startPriceDefaultValue = startPriceValue ? startPriceValue / 100 : null;
  const suggestedStartPriceValue = watch(
    'suggestedStartPrice',
    startPriceValue,
  );

  React.useEffect(() => {
    register('suggestedStartPrice');

    return () => {
      unregister('suggestedStartPrice');
    };
  }, [register, unregister]);

  const { data: seller, loading: loadingSeller } = useSellerQuery({
    variables: {
      inspectionId,
    },
  });

  const [submitToAuction, { loading: isSubmitting }] =
    useEvaSubmitToDirectAuctionMutation({
      onCompleted: () => {
        history.push(appRoutes.completed(true));
        const { expectedPrice, startPrice } = getValues();
        submitTracking('EvaluateDirectAuction', {
          expectedPrice: (expectedPrice as number) * 100,
          startPrice: (startPrice as number) * 100,
        });
      },
      onError: async (error) => {
        const [msg, ...inCompleteMutations] = error.message
          .replace('GraphQL error: ', '')
          .split(', ');

        setNotCompletedSteps(inCompleteMutations);
        formMethods.setError('expectedPrice', {
          message: translatedMsgs.includes(msg)
            ? translations[msg as keyof Translations]
            : msg,
        });
      },
    });

  const { loading, handleRetry } = useStartPrice({
    inspectionId,
    expectedPriceValue,
    startPriceValue,
    translations,
    clearErrors,
    getValues,
    setValue,
    setError,
  });

  const onSubmit = ({ expectedPrice, startPrice }: DirectAuctionForm) => {
    submitToAuction({
      variables: {
        inspectionId,
        expectedPrice: (expectedPrice as number) * 100,
        startPrice: (startPrice as number) * 100,
        termsAccepted,
        failedReasons,
      },
    });
  };

  const getValidationMessage = () => {
    if (loading) {
      return translations.START_PRICE_CALCULATING_HELPER_MESSAGE;
    }
    return expectedPriceValue && suggestedStartPriceValue
      ? `${translations.START_PRICE_HELPER_MESSAGE} €${formatLocaleNumber(
          suggestedStartPriceValue as number,
        )}`
      : '';
  };

  return (
    <Modal
      isOpen
      onOk={formMethods.handleSubmit(onSubmit)}
      title={translations.DIRECT_AUCTION_MODAL_TITLE}
      okLabel={
        <>
          {translations.SUBMIT_24_AUCTION}
          {isSubmitting && <Loader extraClass={styles.nextButtonLoader} />}
        </>
      }
      isOkButtonDisabled={
        (expectedPriceValue && !startPriceValue) ||
        isSubmitting ||
        Object.keys(formErrors).length > 0
      }
      isCancelButtonDisabled={isSubmitting}
      okButtonExtraClass={styles.okButtonExtraClass}
      titleExtraClassName={styles.modalTitle}
      actionContainerExtraClassName={styles.actionContainerExtraClassName}
      className={styles.expectedPriceModal}
      onCancel={handleCancelClick}
      cancelLabel={translations.CANCEL}
      cancelButtonExtraClass={styles.cancelButtonExtraClass}
      qaIdPrefix="direct-auction-modal"
    >
      {loadingSeller ? (
        <>
          <FormInputPlaceholder labelWidth="103px" />
          <Skeleton
            width="100%"
            height="19px"
            style={{ marginBottom: '8px' }}
          />
          <Skeleton width="100%" height="276px" />
        </>
      ) : (
        <FormProvider {...formMethods}>
          <InputLabel additionalClassName={styles.label}>
            {translations.DIRECT_AUCTION_MODAL_EXPECTED_PRICE}
          </InputLabel>
          <FormNumberInput
            name="expectedPrice"
            qaIdPrefix="expected-price-auction"
            maxDigits={7}
            className={styles.priceInput}
            containerClassName={styles.priceContainer}
            icon="€"
          />
          <InputLabel additionalClassName={styles.label}>
            {translations.START_PRICE_LABEL}
          </InputLabel>
          <div className={styles.fieldWithIconWrapper}>
            <FormNumberInput
              name="startPrice"
              qaIdPrefix="start-price-auction"
              maxDigits={7}
              className={styles.priceInput}
              containerClassName={styles.priceContainer}
              icon="€"
              disabled={!expectedPriceValue}
              defaultValue={startPriceDefaultValue}
              validationMsg={getValidationMessage()}
            />
            {loading && <LoadingCircle size={14} color="#606060" />}
          </div>
          {formErrors?.startPrice?.type === 'custom' ? (
            <Button
              theme="outlined"
              isActivated={false}
              onClick={handleRetry}
              qaId={`retry-button`}
            >
              <img src={forwoardIcon} alt="forward icon" />
              {translations.RETRY}
            </Button>
          ) : null}
          <Typography tag="div" additonalClassNames={styles.description}>
            {translations.DIRECT_AUCTION_MODAL_DESCRIPTION}
          </Typography>
          <div className={styles.tableWrapper} data-qa-id="seller-info">
            <table className={styles.table}>
              <tbody>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_COMPANY_NAME}</th>
                  <td data-qa-id="seller-company">
                    {seller?.inspection?.seller?.companyName}
                  </td>
                </tr>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_NAME}</th>
                  <td data-qa-id="seller-first-name">
                    {seller?.inspection?.seller?.firstName}
                  </td>
                </tr>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_LAST_NAME}</th>
                  <td data-qa-id="seller-last-name">
                    {seller?.inspection?.seller?.lastName}
                  </td>
                </tr>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_ADDRESS}</th>
                  <td data-qa-id="seller-address">
                    {seller?.inspection?.seller?.address?.street},{' '}
                    {seller?.inspection?.seller?.address?.zipCode}
                    <br />
                    {seller?.inspection?.seller?.address?.city},{' '}
                    {seller?.inspection?.seller?.address?.country}
                  </td>
                </tr>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_PHONE_NUMBER}</th>
                  <td data-qa-id="seller-phone">
                    {seller?.inspection?.seller?.phoneNumber}
                  </td>
                </tr>
                <tr>
                  <th>{translations.DIRECT_AUCTION_MODAL_EMAIL}</th>
                  <td data-qa-id="seller-email" className={styles.email}>
                    {seller?.inspection?.seller?.email}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </FormProvider>
      )}
    </Modal>
  );
};

export { DirectAuctionModal };
