import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { FormUploader } from 'shared/components/FormUploader';
import { FormDropdown } from 'shared/components/FormDropdown';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { Typography } from 'shared/components/Typography';
import { useTranslation } from 'shared/hooks/useTranslation';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';

import { useDamageOptions } from '../../hooks/useDamageOptions';
import styles from './index.module.scss';

type Props = {
  qaIdPrefix: string;
  fieldName: string;
  index: number;
};

const DamageItem: React.FC<Props> = ({ qaIdPrefix, fieldName }) => {
  const { translations } = useTranslation();
  const { watch, setValue } = useFormContext();
  const selectedArea = watch(`${fieldName}.area`);
  const selectedPart = watch(`${fieldName}.part`);
  const selectedType = watch(`${fieldName}.type`);

  const { areas, parts, types } = useDamageOptions({
    selectedArea,
    selectedPart,
    onCompleted: () => {
      setValue(`${fieldName}.area`, selectedArea);
      setValue(`${fieldName}.part`, selectedPart);
      setValue(`${fieldName}.type`, selectedType);
    },
  });

  return (
    <div data-qa-id={`${qaIdPrefix}-container`}>
      <FormUploader
        allowMultipleFiles
        uploadFileTypes="image/png, image/jpeg, image/heic"
        processSelectedFile={processHeicFile}
        extraClassName={styles.uploaderStyles}
        name={`${fieldName}.files`}
        defaultValue={[]}
        qaIdPrefix={`${qaIdPrefix}-form-uploader`}
      >
        {({ handleUploadClick }) => (
          <>
            <div
              className={styles.uploadCloudIcon}
              data-qa-id={`${qaIdPrefix}-file-uploader`}
            />
            <Typography>{translations.DROP_PHOTOS_HERE}</Typography>
            <button
              type="button"
              data-qa-id={`${qaIdPrefix}-file-uploader-button`}
              className={styles.browseButton}
              onClick={(event) => {
                event.preventDefault();
                handleUploadClick();
              }}
            >
              {translations.BROWSE}
            </button>
          </>
        )}
      </FormUploader>

      <div
        className={styles.carInfoContainer}
        data-qa-id={`${qaIdPrefix}-car-info-container`}
      >
        <FormDropdown
          defaultValue={null}
          name={`${fieldName}.area`}
          label={translations.QUALITY_AREA}
          placeholder={translations.PLEASE_SELECT}
          containerClassName={styles.carInfoColumn}
          qaIdPrefix={`${qaIdPrefix}-car-area-dropdown`}
          options={areas}
          disabled={areas.length === 0}
          inputClassname={styles.dropdown}
          beforeChange={() => {
            setValue(`${fieldName}.part`, null);
            setValue(`${fieldName}.type`, null);
          }}
          searchable
        />
        <FormDropdown
          defaultValue={null}
          name={`${fieldName}.part`}
          label={translations.QUALITY_PART}
          placeholder={translations.PLEASE_SELECT}
          containerClassName={styles.carInfoColumn}
          qaIdPrefix={`${qaIdPrefix}-car-part-dropdown`}
          options={parts}
          disabled={parts.length === 0}
          inputClassname={styles.dropdown}
          beforeChange={() => {
            setValue(`${fieldName}.type`, null);
          }}
          searchable
        />
        <FormDropdown
          defaultValue={null}
          name={`${fieldName}.type`}
          label={translations.DAMAGES_TYPE}
          placeholder={translations.PLEASE_SELECT}
          containerClassName={styles.carInfoColumn}
          qaIdPrefix={`${qaIdPrefix}-damage-type-dropdown`}
          options={types}
          disabled={types.length === 0}
          inputClassname={styles.dropdown}
          searchable
        />
        <FormCheckbox
          qaIdPrefix={`${qaIdPrefix}-accident-reason`}
          name={`${fieldName}.isAccidentReason`}
          defaultValue={null}
          uiLabel={translations.DAMAGES_ACCIDENT_REASON}
          containerClassName={cn(styles.carInfoColumn, styles.checkbox)}
        />
      </div>
    </div>
  );
};

export { DamageItem };
