import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonThemes } from '@auto1-ui/button';
import { Carousel } from 'shared/components/Carousel';
import { ImagePreview } from 'shared/components/ImagePreview';

import { useTranslation } from 'shared/hooks/useTranslation';
import styles from './styles.module.scss';

type FileData = { id?: string; absoluteUrl: string };
type FileTemp = { file: File; absoluteUrl: string };
type FileValue = File | FileData;

type Props = {
  name: string;
};

const loadImageUrl = (file: FileValue): Promise<FileData | FileTemp> =>
  new Promise((resolve, reject) => {
    if (!(file instanceof File)) {
      resolve(file);
    } else {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof e.target?.result === 'string') {
          resolve({
            file,
            absoluteUrl: e.target.result,
          });
        } else {
          reject();
        }
      };
      reader.onerror = (error) => reject(error);

      reader.readAsDataURL(file);
    }
  });

const FormUploaderCarousel: React.FC<Props> = ({ name }) => {
  const { translate } = useTranslation();
  const [images, setImages] = React.useState<
    ReadonlyArray<FileData | FileTemp>
  >([]);
  const { watch, setValue } = useFormContext();
  const files: FileValue[] = watch(name);
  const [selectedImageIndex, setSelectedImageIndex] =
    React.useState<number | null>(null);
  const closePreview = () => setSelectedImageIndex(null);

  React.useEffect(() => {
    let setImagesCallback = (
      filesWithLoadedImages: ReadonlyArray<FileData | FileTemp>,
    ) => {
      setImages(filesWithLoadedImages);
    };

    if (files) {
      Promise.all(files.map(loadImageUrl)).then((...args) => {
        setImagesCallback(...args);
      });
    }

    return () => {
      setImagesCallback = () => {};
    };
  }, [loadImageUrl, files]);

  const deleteSelectedImage = () => {
    if (selectedImageIndex === null) return;
    setValue(
      name,
      [
        ...files.slice(0, selectedImageIndex),
        ...files.slice(selectedImageIndex + 1),
      ],
      { shouldValidate: true, shouldDirty: true },
    );
    closePreview();
  };

  React.useEffect(() => {
    const fileState = watch(name, []);
    if (images.length > 0 && (!fileState || !fileState.length)) {
      setImages([]);
    }
  }, [images, name, watch]);

  return images.length ? (
    <>
      <Carousel
        extraContainerClass={styles.carousel}
        extraimageContainerClass={styles.extraimageContainerClass}
      >
        {images.map((image, index) => {
          return (
            <img
              key={
                'file' in image
                  ? image.file.name
                  : image.id ?? image.absoluteUrl
              }
              src={image.absoluteUrl}
              alt="car-registration"
              onClick={() => setSelectedImageIndex(index)}
            />
          );
        })}
      </Carousel>
      <ImagePreview
        isOpen={selectedImageIndex !== null}
        imageSrc={
          selectedImageIndex !== null
            ? images[selectedImageIndex].absoluteUrl
            : ''
        }
        handleClose={closePreview}
        buttons={[
          {
            label: translate('a1-inspectionApp-fileUploader-delete'),
            alignment: 'right',
            theme: ButtonThemes.outlined,
            onClick: deleteSelectedImage,
          },
        ]}
      />
    </>
  ) : null;
};

export { FormUploaderCarousel };
