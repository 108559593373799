import * as React from 'react';
import cn from 'classnames';
import { ArrowLeft, ArrowRight } from '@auto1-ui/icons';
import styles from './index.module.scss';

type Props = {
  extraContainerClass?: string;
  extraLeftArrowContainerClass?: string;
  extraRightArrowContainerClass?: string;
  extraimageContainerClass?: string;
  qaIdPrefix?: string;
};

const Carousel: React.FC<Props> = ({
  children,
  extraContainerClass = '',
  extraLeftArrowContainerClass = '',
  extraRightArrowContainerClass = '',
  extraimageContainerClass = '',
  qaIdPrefix = 'carousel',
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [areArrowsVisible, setAreArrowsVisible] = React.useState(false);
  const detectScrollbarVisibility = () => {
    const container = containerRef.current;

    if (container) {
      setAreArrowsVisible(container.scrollWidth > container.clientWidth);
    }
  };
  React.useLayoutEffect(detectScrollbarVisibility, []);

  return (
    <div
      className={cn(styles.container, extraContainerClass)}
      ref={containerRef}
      data-qa-id={qaIdPrefix}
    >
      <div
        className={cn(
          styles.arrowContainer,
          styles.left,
          {
            [styles.hide]: !areArrowsVisible,
          },
          extraLeftArrowContainerClass,
        )}
      >
        <ArrowLeft
          onClick={() => {
            const container = containerRef.current;
            if (container) {
              container.scrollBy({
                left: -500,
                behavior: 'smooth',
              });
            }
          }}
        />
      </div>
      {React.Children.map(children, (child) => {
        return (
          <div
            className={cn(styles.imageWrapper, extraimageContainerClass)}
            data-qa-id={`${qaIdPrefix}-item`}
          >
            {child}
          </div>
        );
      })}
      <div
        className={cn(
          styles.arrowContainer,
          styles.right,
          {
            [styles.hide]: !areArrowsVisible,
          },
          extraRightArrowContainerClass,
        )}
      >
        <ArrowRight
          onClick={() => {
            const container = containerRef.current;
            if (container) {
              container.scrollBy({
                left: 500,
                behavior: 'smooth',
              });
            }
          }}
        />
      </div>
    </div>
  );
};

Carousel.defaultProps = {
  extraContainerClass: '',
  extraLeftArrowContainerClass: '',
  extraRightArrowContainerClass: '',
  extraimageContainerClass: '',
  qaIdPrefix: 'carousel',
};

export { Carousel };
