import * as React from 'react';
import { useTranslation } from 'shared/hooks/useTranslation';
import { RoutesEnum } from 'shared/utils/route';
import {
  getSectionProgressYup,
  getPageProgress,
} from 'shared/utils/calculateProgress';
import { useInspection } from 'shared/components/InspectionContext';
import { VEHICLE_MEDIA_AREAS } from '../constants';
import { CarPictureSchema } from '../carPicturesData';

const getTotalFields = (isPdfInspection: boolean) => {
  if (isPdfInspection) {
    return [VEHICLE_MEDIA_AREAS.FRONT_LEFT];
  }
  return [
    VEHICLE_MEDIA_AREAS.SPEEDOMETER,
    VEHICLE_MEDIA_AREAS.REAR_INTERIOR,
    VEHICLE_MEDIA_AREAS.CONSOLE,
    VEHICLE_MEDIA_AREAS.FRONT_INTERIOR,
    VEHICLE_MEDIA_AREAS.FRONT,
    VEHICLE_MEDIA_AREAS.ENGINE,
    VEHICLE_MEDIA_AREAS.FRONT_LEFT,
    VEHICLE_MEDIA_AREAS.FRONT_LEFT_SIDE,
    VEHICLE_MEDIA_AREAS.REAR_LEFT_SIDE,
    VEHICLE_MEDIA_AREAS.REAR,
    VEHICLE_MEDIA_AREAS.REAR_RIGHT_SIDE,
    VEHICLE_MEDIA_AREAS.FRONT_RIGHT_SIDE,
  ];
};

const useProgress = () => {
  const { isPdfInspection, setProgress } = useInspection();
  const { translations } = useTranslation();

  const getCarPicturesProgress = React.useCallback(
    (formValues) => {
      const validationSchema = CarPictureSchema(
        translations,
        false,
        isPdfInspection,
      );

      validationSchema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          setProgress({
            [RoutesEnum.CAR_PICTURES]: 100,
            [RoutesEnum.CAR_PICTURES_MAIN_PHOTOS]: 100,
            [RoutesEnum.CAR_PICTURES_ADDITIONAL_PHOTOS]: 100,
          });
        })
        .catch(({ inner: errors }) => {
          const fields = getTotalFields(isPdfInspection);
          const progress = getSectionProgressYup({
            errors,
            totalFieldsNb: fields.length,
          });

          setProgress({
            [RoutesEnum.CAR_PICTURES]: getPageProgress([progress]),
            [RoutesEnum.CAR_PICTURES_MAIN_PHOTOS]: progress,
            [RoutesEnum.CAR_PICTURES_ADDITIONAL_PHOTOS]: 100,
          });
        });
    },
    [translations, isPdfInspection, setProgress],
  );

  return getCarPicturesProgress;
};

export { useProgress };
