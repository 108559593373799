import * as React from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { FormProvider } from 'react-hook-form';
import { withTransaction } from '@elastic/apm-rum-react';

import { Button, ButtonTypes } from '@auto1-ui/button';

import { useTranslation } from 'shared/hooks/useTranslation';
import { useScrollToSection } from 'shared/utils/useSectionScroll';
import { useInspection } from 'shared/components/InspectionContext';
import { Typography } from 'shared/components/Typography';
import { Card } from 'shared/components/Card';
import { NavigationFooter } from 'shared/components/NavigationFooter';
import { LayoutContainer } from 'shared/components/LayoutContainer';
import Loader from 'shared/components/Loader';
import { AlertBar } from 'shared/components/AlertBar';
import { appRoutes } from 'appRoutes';
import navigationStyle from 'shared/components/NavigationFooter/index.module.scss';
import { RouteLeavingGuard } from 'shared/components/RouteLeavingGuard';
import { useSideMenuNavigationTracking } from 'shared/hooks/useSideMenuNavigationTracking';
import type { Translations } from 'shared/utils/translations';
import { useUploadPool } from 'shared/utils/uploadPool';

import { CarPictureSection } from './components/CarPictureSection';
import { CarGeneralPictureSection } from './components/CarGeneralPictureSection';
import styles from './index.module.scss';
import { useCarPictures } from './hooks/useCarPictures';
import { Placeholder } from './Placeholder';

type Props = {
  qaIdPrefix?: string;
};

const CarPictures: React.FC<Props> = ({ qaIdPrefix = 'car-pictures' }) => {
  const location =
    useLocation<{
      prev?: {
        pathname: string;
        hash: string;
      };
      sideMenuNavigation?: boolean;
    }>();
  const { translations } = useTranslation();
  const { isPdfInspection, inspectionId } = useInspection();

  const {
    methods,
    loading,
    stockNumber,
    outsideSectionItems,
    optionalSectionItems,
    insideSectionItems,
    submitHandler,
    submitErrorHandler,
    submitContext,
    serverError,
  } = useCarPictures();

  useScrollToSection(location.hash);
  useSideMenuNavigationTracking(
    location.state && location.state.prev,
    location,
  );
  const { isUploadPoolEmpty } = useUploadPool();

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = methods;
  const error = Object.values(errors).sort((a) =>
    a.type !== 'required' ? -1 : 1,
  )?.[0];

  if (loading) {
    return <Placeholder />;
  }

  return (
    <LayoutContainer>
      <FormProvider {...methods}>
        <Card paddingTop={32} paddingBottom={32}>
          <Typography
            variant="titleXL"
            tag="div"
            additonalClassNames={styles.title}
            data-qa-id={`${qaIdPrefix}-main-title`}
          >
            {translations.CAR_PICTURES_TITLE}
          </Typography>

          {isPdfInspection ? (
            <AlertBar
              message={error?.message ?? translations.CAR_IMAGES_PDF_DISCLAIMER}
              type={error ? 'error' : 'info'}
              additionalClassNames={styles.subtitle}
            />
          ) : (
            <Typography
              tag="div"
              additonalClassNames={cn(styles.subtitle, {
                [styles.error]: Boolean(error),
              })}
              data-qa-id={`${qaIdPrefix}-main-subtitle`}
            >
              {error?.message ?? translations.PLEASE_UPLOAD_ALL_PICTURES}
            </Typography>
          )}
          {stockNumber && (
            <>
              <CarPictureSection
                title={translations.INSIDE}
                qaIdPrefix={`${qaIdPrefix}-inside-section`}
                items={insideSectionItems}
                stockNumber={stockNumber}
                id="main-photos"
              />
              <CarPictureSection
                title={translations.OUTSIDE}
                qaIdPrefix={`${qaIdPrefix}-outside-section`}
                items={outsideSectionItems}
                stockNumber={stockNumber}
              />
              <CarPictureSection
                title={translations.OPTIONAL_PICTURES_AND_VIDEO}
                qaIdPrefix={`${qaIdPrefix}-optional-section`}
                items={optionalSectionItems}
                stockNumber={stockNumber}
                optional
              />
              <CarGeneralPictureSection
                title={translations.OTHER_PICTURES}
                qaIdPrefix={`${qaIdPrefix}-general-section`}
                stockNumber={stockNumber}
                optional
                id="additional-photos"
              />
            </>
          )}
        </Card>
        <NavigationFooter
          backButtonLink={appRoutes.testDrive(`${inspectionId}`, true)}
          qaPrefix={qaIdPrefix}
          rightAreaContent={
            <>
              <Button
                type={ButtonTypes.button}
                theme="outlined"
                isActivated={isUploadPoolEmpty}
                qaId={`${qaIdPrefix}-footer-save-link`}
                extraClass={navigationStyle.saveButton}
                onClick={() => {
                  submitContext.current = 'save';
                  handleSubmit(submitHandler, submitErrorHandler)();
                }}
                isDisabled={isSubmitting || !isUploadPoolEmpty}
              >
                {translations.SAVE}
                {isSubmitting && submitContext.current === 'save' && (
                  <Loader extraClass={navigationStyle.saveButtonLoader} />
                )}
              </Button>
              <Button
                type={ButtonTypes.button}
                theme="orange"
                extraClass={navigationStyle.nextButton}
                isActivated={isUploadPoolEmpty}
                qaId={`${qaIdPrefix}-footer-next-link`}
                onClick={() => {
                  submitContext.current = 'submit';
                  handleSubmit(submitHandler, submitErrorHandler)();
                }}
                isDisabled={isSubmitting || !isUploadPoolEmpty}
              >
                {translations.NEXT}
                {isSubmitting && submitContext.current === 'submit' && (
                  <Loader extraClass={navigationStyle.nextButtonLoader} />
                )}
              </Button>
            </>
          }
          bottomAreaContent={
            serverError && (
              <AlertBar
                type="error"
                message={
                  serverError in translations
                    ? translations[serverError as keyof Translations]
                    : translations.GENERIC_ERROR_MESSAGE
                }
                additionalClassNames={navigationStyle.alertBar}
              />
            )
          }
        />
        <RouteLeavingGuard when={isDirty} title={translations.UNSAVED_CHANGES}>
          <p>{translations.WOULD_YOU_LIKE_SAVE_CHANGES}</p>
        </RouteLeavingGuard>
      </FormProvider>
    </LayoutContainer>
  );
};

const CarPicturesWithTransaction = withTransaction(
  'CarPictures',
  'component',
)(CarPictures);

export { CarPicturesWithTransaction as CarPictures };
