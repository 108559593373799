import { RoutesEnum } from 'shared/utils/route';

const requiredMutations = (
  isPdfInspection = false,
  isElectricCar = false,
): Partial<Record<keyof typeof RoutesEnum, string[]>> => {
  if (isPdfInspection) {
    return Object.freeze({
      CAR_DETAILS: [
        'msiPdfSubmitEcode',
        'msiPdfSubmitVin',
        'msiSubmitAdditionalData',
        'submitAdditionalData',
      ],
      TEST_DRIVE: ['submitMileage'],
      CAR_PICTURES: ['replaceCarImages'],
      CAR_QUALITY: ['msiSubmitPaintThickness'],
      TIRES_AND_BRAKES: ['submitWheels', 'msiReplaceBrakeConditions'],
      CAR_STATUS: ['msiSubmitOtherInfo', 'submitEquipment'],
      SERVICE_AND_DOCUMENTS: [
        'msiPdfSubmitService',
        'evaReplaceAccidents',
        'msiPdfSubmitTechnicalInspection',
      ],
    });
  }

  return Object.freeze({
    CAR_DETAILS: [
      'msiSubmitEcode',
      'msiSubmitVin',
      'msiSubmitAdditionalData',
      'submitAdditionalData',
      ...(isElectricCar ? ['evaSubmitElectricCarDetails'] : []),
    ],
    TEST_DRIVE: ['submitTestDriveAnswers', 'submitMileage'],
    CAR_PICTURES: ['replaceCarImages'],
    CAR_QUALITY: ['msiSubmitPaintThickness', 'msiReplaceDamages'],
    TIRES_AND_BRAKES: ['submitWheels', 'msiReplaceBrakeConditions'],
    CAR_STATUS: ['msiSubmitOtherInfo', 'submitEquipment'],
    SERVICE_AND_DOCUMENTS: ['msiSubmitService', 'evaReplaceAccidents'],
  });
};

export { requiredMutations };
