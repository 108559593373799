import { appRoutes } from 'appRoutes';
import { RoutesEnum } from 'shared/utils/route';
import { requiredMutations } from 'shared/utils/route2ReqMutationsMap';
import type { Translations } from 'shared/utils/translations';
import { ListItem } from './components/ListItem';

const disabledWhen = ({ isConfirmed }: { isConfirmed: boolean }) =>
  !isConfirmed;
const insertIf = (
  isElectricCar: boolean,
  route: React.ComponentProps<typeof ListItem>,
) => {
  return isElectricCar ? [route] : [];
};

const sideBarRoutes = (
  inspectionId: string,
  translations: Translations,
  isPdfInspection: boolean,
  isElectricCar: boolean,
) => {
  return [
    {
      id: RoutesEnum.CAR_DETAILS,
      name: translations.CAR_DETAILS,
      route: appRoutes.carDetails(inspectionId, true),
      showPercentage: true,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.CAR_DETAILS
      ],
      disabled: false,
      children: [
        {
          id: RoutesEnum.CAR_DETAILS_CAR_INFO,
          showPercentage: true,
          name: translations.CAR_INFO,
          hash: '#carInfo',
          route: appRoutes.carDetails(inspectionId, true),
          disabled: false,
        },
        {
          disabled: disabledWhen,
          id: RoutesEnum.CAR_DETAILS_ADDITIONAL_INFO,
          showPercentage: true,
          name: translations.ADDITIONAL_INFO,
          hash: '#additionalInfo',
          route: appRoutes.carDetails(inspectionId, true),
        },
        ...insertIf(isElectricCar, {
          disabled: disabledWhen,
          id: RoutesEnum.CAR_DETAILS_ELECTRIC_CAR_INFO,
          showPercentage: true,
          name: translations.ELECTRIC_CAR_INFO,
          hash: '#electricCarInfo',
          route: appRoutes.carDetails(inspectionId, true),
        }),
      ],
    },
    {
      id: RoutesEnum.TEST_DRIVE,
      name: translations.TEST_DRIVE,
      route: appRoutes.testDrive(inspectionId, true),
      showPercentage: true,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.TEST_DRIVE
      ],
      disabled: disabledWhen,
      children: [
        {
          id: RoutesEnum.TEST_DRIVE_MILEAGE,
          name: translations.MILEAGE,
          hash: '#mileage',
          route: appRoutes.testDrive(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.TEST_DRIVE_TEST_DRIVE,
          name: translations.TEST_DRIVE,
          hash: '#test-drive',
          route: appRoutes.testDrive(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.TEST_DRIVE_FINDINGS,
          name: translations.FINDINGS,
          hash: '#findings',
          route: appRoutes.testDrive(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
      ],
    },
    {
      id: RoutesEnum.CAR_PICTURES,
      name: translations.CAR_PICTURES,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.CAR_PICTURES
      ],
      route: appRoutes.carPictures(inspectionId, true),
      showPercentage: true,
      disabled: disabledWhen,
      children: [
        {
          id: RoutesEnum.CAR_PICTURES_MAIN_PHOTOS,
          name: translations.MAIN_CAR_PHOTOS,
          hash: '#main-photos',
          route: appRoutes.carPictures(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.CAR_PICTURES_ADDITIONAL_PHOTOS,
          name: translations.OTHER_PICTURES,
          hash: '#additional-photos',
          route: appRoutes.carPictures(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
      ],
    },
    {
      id: RoutesEnum.CAR_QUALITY,
      name: translations.CAR_QUALITY,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.CAR_QUALITY
      ],
      route: appRoutes.carQuality(inspectionId, true),
      showPercentage: true,
      disabled: disabledWhen,
      children: [
        {
          id: RoutesEnum.CAR_QUALITY_THICKNESS,
          name: translations.THICKNESS,
          hash: '#thickness',
          route: appRoutes.carQuality(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.CAR_QUALITY_DAMAGES,
          name: translations.DAMAGES,
          hash: '#damages',
          route: appRoutes.carQuality(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.CAR_QUALITY_HIGHLIGHTS,
          name: translations.HIGHLIGHTS,
          hash: '#highlights',
          route: appRoutes.carQuality(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
      ],
    },
    {
      id: RoutesEnum.TIRES_AND_BRAKES,
      name: translations.TIRES_AND_BRAKES,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.TIRES_AND_BRAKES
      ],
      route: appRoutes.tiresBrakes(inspectionId, true),
      showPercentage: true,
      disabled: disabledWhen,
      children: [
        {
          id: RoutesEnum.TIRES_AND_BRAKES_INSTALLED_WHEELS,
          name: translations.INSTALLED_WHEELS,
          disabled: disabledWhen,
          hash: '#installed-wheels',
          route: appRoutes.tiresBrakes(inspectionId, true),
          showPercentage: true,
        },
        {
          id: RoutesEnum.TIRES_AND_BRAKES_EXTRA_WHEELS,
          name: translations.EXTRA_WHEEL_TITLE,
          hash: '#extra-wheels',
          route: appRoutes.tiresBrakes(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
      ],
    },
    {
      id: RoutesEnum.CAR_STATUS,
      name: translations.CAR_STATUS,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.CAR_STATUS
      ],
      route: appRoutes.carStatus(inspectionId, true),
      disabled: disabledWhen,
      showPercentage: true,
      children: [
        {
          id: RoutesEnum.CAR_STATUS_EQUIPMENTS,
          name: translations.EQUIPMENT,
          hash: '#equipment',
          route: appRoutes.carStatus(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.CAR_STATUS_OTHER_EQUIPMENTS,
          name: translations.OTHER_EQUIPMENT,
          hash: '#other-equipment',
          route: appRoutes.carStatus(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.CAR_STATUS_OTHER_INFORMATION,
          name: translations.OTHER_INFORMATION,
          hash: '#other-information',
          route: appRoutes.carStatus(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
      ],
    },
    {
      id: RoutesEnum.SERVICE_AND_DOCUMENTS,
      name: translations.SERVICE_AND_DOCUMENTS,
      requiredMutations: requiredMutations(isPdfInspection, isElectricCar)[
        RoutesEnum.SERVICE_AND_DOCUMENTS
      ],
      route: appRoutes.serviceDocuments(inspectionId, true),
      disabled: disabledWhen,
      showPercentage: true,
      children: [
        {
          id: RoutesEnum.SERVICE_AND_DOCUMENTS_SERVICE,
          name: translations.SERVICE,
          hash: '#service',
          route: appRoutes.serviceDocuments(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.SERVICE_AND_DOCUMENTS_ACCIDENTS,
          name: translations.ACCIDENTS,
          hash: '#accidents',
          route: appRoutes.serviceDocuments(inspectionId, true),
          showPercentage: true,
          disabled: disabledWhen,
        },
        {
          id: RoutesEnum.SERVICE_AND_DOCUMENTS_OTHER_DOCUMENTS,
          name: translations.OTHER_DOCUMENTS,
          disabled: disabledWhen,
          hash: '#other-documents',
          route: appRoutes.serviceDocuments(inspectionId, true),
          showPercentage: true,
        },
      ],
    },
    {
      id: RoutesEnum.SUMMARY,
      name: translations.SUMMARY,
      route: appRoutes.summary(inspectionId, true),
      disabled: disabledWhen,
    },
  ];
};

export { sideBarRoutes, RoutesEnum };
