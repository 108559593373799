import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cn from 'classnames';
import kebabCase from 'lodash/kebabCase';
import { ArrowRight } from '@auto1-ui/icons';
import { Checked } from 'shared/components/Icons';
import { useInspection } from 'shared/components/InspectionContext';
import classes from '../index.module.scss';

type Percentages = ReturnType<typeof useInspection>['percentages'];

type Props = {
  id: keyof Percentages;
  route: string;
  hash?: string;
  name: string;
  className?: string;
  isChild?: boolean;
  notCompleted?: boolean;
  disabled:
    | boolean
    | ((args: { percentages?: Percentages; isConfirmed: boolean }) => boolean);
  showPercentage?: boolean;
  children?: ReadonlyArray<Props>;
};

const ListItem: React.FC<Props> = ({
  id,
  route,
  hash = '',
  className = '',
  children = null,
  showPercentage = false,
  disabled = false,
  notCompleted = false,
  name,
  isChild = false,
}) => {
  const { percentages, isConfirmed } = useInspection();
  const percentage = percentages[id];
  const hasChildren = Boolean(children);

  const location = useLocation();
  const [locationWithHash, setLocationWithHash] = React.useState(
    location.pathname + location.hash,
  );
  const [isActive, setIsActive] = React.useState(
    !hasChildren && locationWithHash.includes(`${route}${hash}`),
  );
  const [isOpen, setIsOpen] = React.useState(
    hasChildren && locationWithHash.includes(`${route}`),
  );

  const [showCheckmark, setShowCheckmark] = React.useState(isChild);

  React.useEffect(() => {
    setLocationWithHash(location.pathname + location.hash);
  }, [location]);

  React.useEffect(() => {
    setIsActive(!hasChildren && locationWithHash.includes(`${route}${hash}`));
    setIsOpen(hasChildren && locationWithHash.includes(`${route}`));
  }, [locationWithHash, hasChildren, route, hash]);

  React.useEffect(() => {
    setShowCheckmark(isChild && percentage === 100);
  }, [isChild, percentage]);

  const isDisabled = React.useMemo(
    () =>
      typeof disabled === 'function'
        ? disabled({ percentages, isConfirmed })
        : disabled,
    [percentages, isConfirmed, disabled],
  );

  const handleListItemClick = React.useCallback(
    (evt) => {
      if (hasChildren) {
        evt.preventDefault();
        setIsOpen((state) => !state);
      }
    },
    [hasChildren],
  );

  return (
    <li
      key={id}
      data-qa-id={`${kebabCase(id)}-item`}
      className={cn(
        classes.sidebarListItem,
        {
          [classes.active]: isActive,
          [classes.open]: isOpen,
          [classes.isChild]: isChild,
          [classes.disabled]: isDisabled,
          [classes.notCompleted]: notCompleted,
        },
        className,
      )}
    >
      <h4 className={classes.pageName}>
        {hasChildren && <ArrowRight className={classes.arrowIcon} />}
        <Link
          to={{
            pathname: route,
            hash,
            state: {
              sideMenuNavigation: !hasChildren,
              prev: {
                pathname: location.pathname,
                hash: location.hash,
              },
            },
          }}
          className={cn({
            [classes.disabled]: isDisabled,
            [classes.notCompleted]: notCompleted,
          })}
          onClick={handleListItemClick}
        >
          {name}
        </Link>
        <div data-qa-id={`${kebabCase(id)}-progress`}>
          {showPercentage && !showCheckmark && (
            <span
              className={cn(classes.percentage, {
                [classes.completed]: percentage === 100,
                [classes.disabled]: isDisabled,
                [classes.notCompleted]: notCompleted,
              })}
            >
              {percentage || 0}%
            </span>
          )}
          {showCheckmark && (
            <span className={cn(classes.percentage)}>
              <Checked fill="#a0a0a0" width={12} height={9} />
            </span>
          )}
        </div>
      </h4>
      {children?.length && (
        <ul className={classes.sideBarList}>
          {children.map((item) => (
            <ListItem key={item.id} {...item} isChild />
          ))}
        </ul>
      )}
    </li>
  );
};

export { ListItem };
