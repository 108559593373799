import { heicConvert } from 'shared/utils/heic-convert';

function processHeicFile(file: File): Promise<File> {
  if (file.type === 'image/heic') {
    return heicConvert(file);
  }

  return Promise.resolve(file);
}

export { processHeicFile };
