import type { VehicleMimeType } from 'gql/graphql';

function mimeTypeToVehicleMimeType(mimeType: string): VehicleMimeType | null {
  switch (mimeType) {
    case 'image/jpeg':
      return 'JPEG';
    case 'image/png':
      return 'PNG';
    case 'video/mp4':
      return 'MP4';
    default:
      return null;
  }
}

export { mimeTypeToVehicleMimeType };
