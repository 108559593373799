import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { WithLayout } from 'shared/components/withLayout';
import { DEFAULT_LOCALE } from 'language/i18n';
import isMobileEvaluatorApp from 'shared/utils/isMobileEvaluatorApp';
import { withUserPermissions } from 'shared/components/PermissionsContext';
import { userActions } from 'shared/constants';
import { useMobileDetector } from 'shared/hooks/useMobileDetector';
import { InspectionProvider } from 'shared/components/InspectionContext';
import { addProxyToTracking } from 'shared/utils/tracking';
import { useRumDevtools } from 'shared/components/ElasticRUM';
import { isLocalEnv } from 'shared/utils/isProductionUrl';
import { ErrorBoundary } from 'shared/components/ErrorBoundary';
import { apolloClient } from './config/apolloClient';
import { GuestRoute } from './auth/components/GuestRoute';
import LanguageProvider from './language/components/LanguageProvider';
import { appRoutes } from './appRoutes';
import { CarInformationPage } from './pages/CarInformation';
import { PdfInspectionInfo } from './pages/PdfInspectionInfo';
import { DividedLayout, Layout } from './shared/components/Layout';
import { CarDetails } from './pages/CarDetails';
import { ManualFlow } from './pages/ManualFlow';
import { CarPictures } from './pages/CarPictures';
import { CarQuality } from './pages/CarQuality';
import { TestDrive } from './pages/TestDrive';
import { SideBar } from './shared/components/Sidebar';
import { TiresBrakes } from './pages/TiresBrakes';
import { CarStatus } from './pages/CarStatus';
import { ServicesDocuments } from './pages/ServicesDocuments';
import { Summary } from './pages/Summary';
import { InspectionListPage } from './pages/InspectionsList';
import { StockNumberRedirection } from './pages/StockNumberRedirection';
import { ErrorMessagePage } from './pages/ErrorMessagePage';
import { CompletedInspection } from './pages/CompletedInspection';
import { RouteHandler } from './auth/components/RouteHandler';
import { LoginPage } from './pages/LoginPage';
import { LocationsListPage } from './pages/LocationsList';
import { EditLocationPage } from './pages/locationsForm';
import { MobilePage } from './pages/MobilePage';
import { NotFoundPage } from './pages/NotFoundPage';
import { ConvertToFullEva } from './pages/ConvertToFullEva';
import 'shared/components/ElasticRUM/init';

import './shared/styles/index.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

addProxyToTracking();

const DividedLayoutComponent: React.FC = ({ children }) => (
  <DividedLayout sidebar={<SideBar />}>{children}</DividedLayout>
);

const Routes = () => {
  return (
    <Switch>
      <GuestRoute
        path={appRoutes.login()}
        exact
        component={() => <WithLayout layout={Layout} child={LoginPage} />}
      />
      <GuestRoute
        path={appRoutes.notFound()}
        exact
        component={() => (
          <WithLayout layout={Layout} child={() => <NotFoundPage />} />
        )}
      />

      <RouteHandler exact path={appRoutes.errorMessagePage()}>
        <WithLayout layout={Layout} child={ErrorMessagePage} />
      </RouteHandler>

      <RouteHandler exact path={appRoutes.inspectionList()}>
        <WithLayout layout={Layout} child={InspectionListPage} />
      </RouteHandler>

      <RouteHandler exact path={appRoutes.locationsList()}>
        <WithLayout layout={Layout} child={LocationsListPage} />
      </RouteHandler>

      <RouteHandler path={appRoutes.editLocation()}>
        <WithLayout layout={Layout} child={EditLocationPage} />
      </RouteHandler>

      <RouteHandler path={appRoutes.addLocation()}>
        <WithLayout layout={Layout} child={EditLocationPage} />
      </RouteHandler>
      <RouteHandler exact path={appRoutes.convertToFullEva()}>
        <WithLayout layout={Layout} child={ConvertToFullEva} />
      </RouteHandler>
      <RouteHandler exact path={appRoutes.stockNumberRedirection()}>
        <WithLayout layout={Layout} child={StockNumberRedirection} />
      </RouteHandler>
      <RouteHandler exact path={appRoutes.carInfo()}>
        <WithLayout layout={Layout} child={CarInformationPage} />
      </RouteHandler>
      <RouteHandler
        exact
        path={appRoutes.pdfInspectionCarInfo()}
        permissions={[userActions.CAN_CREATE_PDF_INSPECTION]}
      >
        <WithLayout layout={Layout} child={PdfInspectionInfo} />
      </RouteHandler>
      <RouteHandler path={appRoutes.completed()} exact>
        <WithLayout layout={Layout} child={CompletedInspection} />
      </RouteHandler>
      <RouteHandler path={appRoutes.manualFlowWithBranch()} exact>
        <WithLayout layout={DividedLayoutComponent} child={ManualFlow} />
      </RouteHandler>

      <InspectionProvider>
        <RouteHandler path={appRoutes.manualFlow()} exact>
          <WithLayout layout={DividedLayoutComponent} child={ManualFlow} />
        </RouteHandler>
        <RouteHandler path={appRoutes.carDetails()} exact>
          <WithLayout layout={DividedLayoutComponent} child={CarDetails} />
        </RouteHandler>
        <RouteHandler path={appRoutes.testDrive()} exact>
          <WithLayout layout={DividedLayoutComponent} child={TestDrive} />
        </RouteHandler>
        <RouteHandler path={appRoutes.carPictures()} exact>
          <WithLayout layout={DividedLayoutComponent} child={CarPictures} />
        </RouteHandler>
        <RouteHandler path={appRoutes.carQuality()} exact>
          <WithLayout layout={DividedLayoutComponent} child={CarQuality} />
        </RouteHandler>
        <RouteHandler path={appRoutes.tiresBrakes()} exact>
          <WithLayout layout={DividedLayoutComponent} child={TiresBrakes} />
        </RouteHandler>
        <RouteHandler path={appRoutes.carStatus()} exact>
          <WithLayout layout={DividedLayoutComponent} child={CarStatus} />
        </RouteHandler>
        <RouteHandler path={appRoutes.serviceDocuments()} exact>
          <WithLayout
            layout={DividedLayoutComponent}
            child={ServicesDocuments}
          />
        </RouteHandler>
        <RouteHandler path={appRoutes.summary()} exact>
          <WithLayout layout={DividedLayoutComponent} child={Summary} />
        </RouteHandler>
      </InspectionProvider>

      <Redirect from="*" push to={appRoutes.notFound()} />
    </Switch>
  );
};

const RoutesWithUserPermissions = withUserPermissions(Routes);

const App = () => {
  const params = window.location.pathname.split('/');
  const [, lang = DEFAULT_LOCALE] = params;
  const { isMobile, isAndroid } = useMobileDetector();
  useRumDevtools(isLocalEnv());

  if (isMobile && !isMobileEvaluatorApp()) {
    return (
      <Router>
        <LanguageProvider locale={lang}>
          <MobilePage isAndroid={isAndroid} />
        </LanguageProvider>
      </Router>
    );
  }

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <Router>
          <LanguageProvider locale={lang}>
            <RoutesWithUserPermissions />
          </LanguageProvider>
        </Router>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default App;
