import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { Tooltip } from '@auto1-ui/tooltip';
import { Button } from '@auto1-ui/button';
import kebabCase from 'lodash/kebabCase';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import type { AutoPriceStatus } from 'gql/graphql';
import forwoardIcon from 'assets/icons/forward.svg';
import Loader from 'shared/components/Loader';
import { LoadingCircle } from 'shared/components/LoaderCircle';
import { InputLabel } from 'shared/components/InputLabel';
import { Modal } from 'shared/components/Modal';
import { Typography } from 'shared/components/Typography';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { useTranslation } from 'shared/hooks/useTranslation';
import { formatLocaleNumber } from 'shared/utils/formatLocaleNumber';
import { filterImagesByArea } from 'shared/utils/filterImagesByArea';
import { useSubmitToAuction } from '../../hooks/useSubmitToAuction';
import { useStartPrice } from '../../hooks/useStartPrice';
import type { SubmitAuctionForm, CarInfoType } from '../../types';
import { SubmitToAuctionModal } from '../../constants';
import { AutoPrice } from './AutoPrice';
import styles from './index.module.scss';

type Props = {
  selectedAuctionModal: Extract<
    keyof typeof SubmitToAuctionModal,
    'BatchAuction' | 'FullService'
  >;
  submitTracking: (
    action: string,
    payload: { expectedPrice: number; startPrice: number },
  ) => void;
  piPrice: {
    fixedPrice?: number | null;
    maxPrice?: number | null;
    minPrice?: number | null;
    originalPrice?: number | null;
  } | null;
  autoPrice?: {
    status: AutoPriceStatus;
    priceInMinorUnits?: number | null;
  };
  expectedPrice?: number | null;
  auctionType?: {
    id: number;
    duration: number;
  };
  handleCancelClick: () => void;
  termsAccepted: boolean;
  failedReasons: number[];
  carInfo: CarInfoType;
  qaIdPrefix?: string;
};

const AuctionModal: React.FC<Props> = ({
  selectedAuctionModal,
  submitTracking,
  piPrice,
  autoPrice,
  expectedPrice: suggestedExpectedPrice,
  auctionType,
  handleCancelClick,
  termsAccepted,
  failedReasons,
  carInfo,
  qaIdPrefix = 'submit-to-auction-modal',
}) => {
  const hasPiPrice = !!piPrice;
  const hasAutoPrice = !!autoPrice;
  const hasNotAutoPricePrice =
    autoPrice?.status === 'FINISHED' && autoPrice.priceInMinorUnits === null;
  const hasAutoPricePrice =
    autoPrice?.status === 'FINISHED' && autoPrice.priceInMinorUnits !== null;
  const isFixedPrice =
    piPrice?.fixedPrice !== null && piPrice?.fixedPrice !== undefined;
  const { inspectionId } = useParams<{ inspectionId: string }>();
  const { translations, translate } = useTranslation();
  const formMethods = useForm<SubmitAuctionForm>({
    resolver: yupResolver(
      yup.object({
        expectedPrice: yup
          .number()
          .nullable(true)
          .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
          .min(1, translations.GREATER_THAN_ZERO),
        startPrice: yup
          .number()
          .nullable(true)
          .required(translations.THIS_FIELD_CANNOT_BE_EMPTY)
          .test(
            'is-less-than-max',
            translations.START_PRICE_ERROR_MESSAGE_HIGHER_THAN_MAX,
            (value, context) => {
              const expectedPrice = context.parent.expectedPrice;
              if (!value || !expectedPrice) {
                return true;
              }
              return value <= expectedPrice;
            },
          )
          .test('is-more-than-min', (value, context) => {
            const suggestedStartPrice = context.parent.suggestedStartPrice;

            if (!value || !suggestedStartPrice) {
              return true;
            }
            if (value >= suggestedStartPrice) {
              return true;
            }
            return context.createError({
              message: `${
                translations.START_PRICE_ERROR_MESSAGE_LOWER_THAN_MIN
              } €${formatLocaleNumber(suggestedStartPrice as number)}`,
              path: 'startPrice',
            });
          }),
      }),
    ),
  });

  const {
    watch,
    clearErrors,
    getValues,
    setError,
    setValue,
    handleSubmit,
    register,
    unregister,
    formState: { errors: formErrors },
  } = formMethods;

  React.useEffect(() => {
    register('suggestedStartPrice');

    return () => {
      unregister('suggestedStartPrice');
    };
  }, [register, unregister]);

  const expectedPriceDefaultValue = suggestedExpectedPrice
    ? suggestedExpectedPrice / 100
    : null;
  const expectedPriceValue = watch('expectedPrice', expectedPriceDefaultValue);
  const startPriceValue = watch('startPrice');
  const startPriceDefaultValue =
    suggestedExpectedPrice && startPriceValue ? startPriceValue / 100 : null;
  const suggestedStartPriceValue = watch(
    'suggestedStartPrice',
    startPriceValue,
  );

  const shouldShowAutoPrice =
    hasAutoPrice &&
    (autoPrice?.status === 'RUNNING' ||
      (hasNotAutoPricePrice && !hasPiPrice) ||
      hasAutoPricePrice);
  const shouldShowPI = !shouldShowAutoPrice && hasPiPrice;

  const { loading, handleRetry } = useStartPrice({
    inspectionId: parseInt(inspectionId, 10),
    expectedPriceValue,
    startPriceValue,
    translations,
    clearErrors,
    getValues,
    setValue,
    setError,
  });

  const [submitAuction, { loading: isSubmitting }] = useSubmitToAuction({
    auctionModal: selectedAuctionModal,
    setError,
    submitTracking: () => {
      const { expectedPrice, startPrice } = getValues();
      submitTracking(
        selectedAuctionModal === 'FullService'
          ? 'EvaluateFullServiceAuction'
          : 'EvaluateBatchAuction',
        {
          expectedPrice: (expectedPrice as number) * 100,
          startPrice: (startPrice as number) * 100,
        },
      );
    },
  });

  const onSubmit = async ({ expectedPrice, startPrice }: SubmitAuctionForm) => {
    await submitAuction({
      variables: {
        inspectionId: parseInt(inspectionId, 10),
        expectedPrice: (expectedPrice as number) * 100,
        startPrice: (startPrice as number) * 100,
        termsAccepted,
        failedReasons,
        auctionTypeId: null,
      },
    });
  };

  const title = {
    [SubmitToAuctionModal.FullService]:
      translations.SET_EXPECTATION_PRICE_FOR_24H_AUCTION,
    [SubmitToAuctionModal.BatchAuction]:
      translations.SET_EXPECTATION_PRICE_FOR_BATCH_AUCTION,
  };
  const sideNote = {
    [SubmitToAuctionModal.FullService]:
      translations.REMARKETING_24H_AUCTION_SIDE_NOTE,
    [SubmitToAuctionModal.BatchAuction]: translations.BATCH_AUCTION_SIDE_NOTE,
  };

  const getValidationMessage = () => {
    if (loading) {
      return translations.START_PRICE_CALCULATING_HELPER_MESSAGE;
    }

    return expectedPriceValue && suggestedStartPriceValue
      ? `${translations.START_PRICE_HELPER_MESSAGE} €${formatLocaleNumber(
          suggestedStartPriceValue as number,
        )}`
      : '';
  };

  const { vehicle, lastChangedTime, car } = carInfo ?? {};
  const evaluationUpdated = lastChangedTime
    ? format(new Date(lastChangedTime), 'dd/MM/yyyy')
    : '';
  const imgUrl = filterImagesByArea(vehicle?.images, 'FRONT_LEFT');

  return (
    <Modal
      isOpen
      onOk={handleSubmit(onSubmit)}
      title={title[selectedAuctionModal]}
      okLabel={
        <>
          {translations.CONFIRM_SUBMIT_AUCTION}
          {isSubmitting && <Loader extraClass={styles.confirmButtonLoader} />}
        </>
      }
      isOkButtonDisabled={
        (expectedPriceValue && !startPriceValue) ||
        isSubmitting ||
        Object.keys(formErrors).length > 0
      }
      isCancelButtonDisabled={isSubmitting}
      okButtonExtraClass={styles.okButtonExtraClass}
      titleExtraClassName={styles.modalTitle}
      className={styles.expectedPriceModal}
      onCancel={handleCancelClick}
      cancelButtonExtraClass={styles.cancelButtonExtraClass}
      cancelLabel={translations.CANCEL}
      qaIdPrefix={`${qaIdPrefix}${
        selectedAuctionModal === SubmitToAuctionModal.BatchAuction
          ? `-${kebabCase(selectedAuctionModal)}`
          : ''
      }`}
      bodyClassName={styles.bodyClassName}
      actionContainerExtraClassName={styles.actionContainer}
    >
      <div className={styles.headerContainer}>
        <div className={styles.carImage}>
          {imgUrl && <img src={imgUrl} alt="warning-modal-car" />}
        </div>
        <div
          className={styles.carInfo}
          data-qa-id={`${qaIdPrefix}-header-content-container`}
        >
          <Typography
            additonalClassNames={styles.title}
            tag="div"
            data-qa-id={`${qaIdPrefix}-header-title`}
          >
            {car?.stockNumber} - {vehicle?.model?.make} {vehicle?.model?.model}{' '}
            {vehicle?.model?.subModel}
          </Typography>
          <Typography
            variant="textSmall"
            tag="div"
            data-qa-id={`${qaIdPrefix}-header-info`}
          >
            {evaluationUpdated}&nbsp;&nbsp;&#8226;&nbsp;&nbsp;
            {vehicle?.condition?.mileage ?? ''} Km
          </Typography>
        </div>
      </div>
      <div className={styles.bodyContainer}>
        <div className={styles.contentWrapper}>
          <FormProvider {...formMethods}>
            {shouldShowAutoPrice && <AutoPrice {...autoPrice} />}
            {shouldShowPI && (
              <p data-qa-id="piPrice" className={styles.priceIndicator}>
                {translations.AUTO1_PRICE_INDICATOR}:&nbsp;
                <strong>
                  {isFixedPrice
                    ? `€${formatLocaleNumber(
                        (piPrice?.fixedPrice as number) / 100,
                      )}`
                    : `€${formatLocaleNumber(
                        (piPrice?.minPrice as number) / 100,
                      )} - €${formatLocaleNumber(
                        (piPrice?.maxPrice as number) / 100,
                      )}`}
                </strong>
              </p>
            )}
            <InputLabel additionalClassName={styles.label}>
              {translations.EXPECTATION_PRICE}
            </InputLabel>
            <div className={styles.fieldWithIconWrapper}>
              <FormNumberInput
                name="expectedPrice"
                qaIdPrefix="expected-price-auction"
                maxDigits={7}
                className={styles.priceInput}
                containerClassName={styles.priceContainer}
                icon="€"
                defaultValue={expectedPriceDefaultValue}
              />
            </div>
            <InputLabel additionalClassName={styles.label}>
              {translations.START_PRICE_LABEL}
            </InputLabel>
            <div className={styles.fieldWithIconWrapper}>
              <FormNumberInput
                name="startPrice"
                qaIdPrefix="start-price-auction"
                maxDigits={7}
                className={styles.priceInput}
                containerClassName={styles.priceContainer}
                icon="€"
                disabled={!expectedPriceValue}
                defaultValue={startPriceDefaultValue}
                validationMsg={getValidationMessage()}
              />
              {loading && <LoadingCircle size={14} color="#606060" />}
            </div>
            {formErrors?.startPrice?.type === 'custom' ? (
              <Button
                theme="outlined"
                isActivated={false}
                extraClass={styles.retryButton}
                onClick={handleRetry}
                qaId={`${qaIdPrefix}-retry-button`}
              >
                <img src={forwoardIcon} alt="forward icon" />
                {translations.RETRY}
              </Button>
            ) : null}
            {selectedAuctionModal !== SubmitToAuctionModal.BatchAuction &&
            auctionType ? (
              <>
                <div
                  className={styles.noteAuctionType}
                  data-qa-id="auction-type-duration"
                >
                  {translate(translations.DYNAMIC_AUCTION_AUCTION_TYPE, {
                    auctionDuration: auctionType?.duration,
                  })}
                  &nbsp;
                  <Tooltip
                    arrowPlacement="bottom"
                    extraClass={styles.tooltip}
                    contentExtraClass={styles.tooltipContent}
                  >
                    <h4>{translations.DYNAMIC_AUCTION}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: translations.DYNAMIC_AUCTION_TOOLTIP_TEXT,
                      }}
                    />
                  </Tooltip>
                </div>
                <p className={styles.note} data-qa-id="auction-type-note">
                  {translations.DYNAMIC_AUCTION_MODAL_NOTE}
                </p>
              </>
            ) : null}
            {shouldShowPI && (
              <p className={styles.message}>{translations.PRICE_ADVISE}</p>
            )}
          </FormProvider>
        </div>
        <div
          className={styles.sideBar}
          data-qa-id="submit-to-auction-note"
          dangerouslySetInnerHTML={{ __html: sideNote[selectedAuctionModal] }}
        />
      </div>
    </Modal>
  );
};

export { AuctionModal };
