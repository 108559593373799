import omit from 'lodash/omit';
import { useMutation } from '@apollo/client';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { fileFilter } from 'shared/utils/fileFilter';
import {
  ServiceDocumentsUpdaterDocument,
  PdfServiceDocumentsUpdaterDocument,
} from 'gql/graphql';
import type { VehicleImageInput, PointOfInterestInput } from 'gql/graphql';
import { mimeTypeToVehicleMimeType } from 'shared/utils/mimeType';

import { SERVICE_BOOK_AREA, SERVICE_BOOK_PART } from '../constants';
import type { Mutation, ServiceMutation, AccidentMutation } from '../types';

type ImagesToUpload = Array<
  | VehicleImageInput
  | {
      absoluteUrl: string;
      mimeType: string;
      pointsOfInterest?: PointOfInterestInput[] | null;
    }
>;

function useServiceDocumentsUpdater({
  stockNumber,
  isPdfInspection,
}: {
  stockNumber: string;
  isPdfInspection: boolean;
}) {
  const [mutate, mutationResult] = useMutation(
    isPdfInspection
      ? PdfServiceDocumentsUpdaterDocument
      : ServiceDocumentsUpdaterDocument,
  );
  const { uploadMedia: uploadServiceBookMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );
  const { uploadMedia: uploadAccidentMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const serviceDataBuilder = (
    data: ServiceMutation,
    imagesToUpload: ImagesToUpload,
  ) => {
    return {
      ...data,
      serviceBookImages: (imagesToUpload || []).map((image) => {
        const imageToUpload =
          image instanceof File ? image : omit(image, '__typename');
        return {
          ...imageToUpload,
          area: SERVICE_BOOK_AREA,
          part: SERVICE_BOOK_PART,
        };
      }),
    };
  };

  const accidentDataBuilder = (
    data: AccidentMutation,
    imagesToUpload: ImagesToUpload,
  ) => {
    const { hasHadAccident, ...restData } = data;
    if (!hasHadAccident) {
      return [];
    }

    const dataForMutation = {
      ...restData,
      invoiceImages: imagesToUpload.map((image) =>
        image instanceof File ? image : omit(image, '__typename'),
      ),
    };
    return [dataForMutation];
  };

  const updateServiceDocuments = async (
    variables: Mutation & { inspectionId: number; incomplete: boolean },
  ) => {
    const {
      inspectionId,
      incomplete,
      service,
      accident,
      hasHadAccident,
      inspectionReport,
    } = variables;
    const serviceBookImages = fileFilter(service?.serviceBookImages || []);
    const invoiceImages = fileFilter(accident?.invoiceImages || []);
    const [serviceBookImagesToUpload, invoiceImagesToUpload] =
      await Promise.all([
        uploadServiceBookMedia(
          serviceBookImages.files.map((file) => ({
            stockNumber,
            file,
            part: `vehicle__${SERVICE_BOOK_AREA}__${SERVICE_BOOK_PART}`.toLowerCase(),
            source: 11,
          })),
        ).then((results) => [
          ...serviceBookImages.previouslyUploadedImages,
          ...results.map(
            ({
              data: {
                wkda: { url },
              },
              mimeType,
            }) => ({
              absoluteUrl: url,
              mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
              pointsOfInterest: [],
            }),
          ),
        ]),
        uploadAccidentMedia(
          invoiceImages.files.map((file) => ({
            stockNumber,
            file,
            part: 'vehicle__accident_image',
            source: 11,
          })),
        ).then((results) => [
          ...invoiceImages.previouslyUploadedImages,
          ...results.map(
            ({
              data: {
                wkda: { url },
              },
              mimeType,
            }) => ({
              absoluteUrl: url,
              mimeType: mimeTypeToVehicleMimeType(mimeType) ?? 'JPEG',
              pointsOfInterest: [],
            }),
          ),
        ]),
      ]);
    const technicalInspectionReport = inspectionReport?.absoluteUrl
      ? {
          absoluteUrl: inspectionReport?.absoluteUrl,
          mimeType: 'APPLICATION_PDF',
          type: 'REPORT',
        }
      : null;

    return mutate({
      variables: {
        inspectionId,
        incomplete,
        hasHadAccident,
        service: serviceDataBuilder(service, serviceBookImagesToUpload),
        accidents: accidentDataBuilder(accident, invoiceImagesToUpload),
        otherNote: variables.otherNote ?? '',
        inspectionReport: technicalInspectionReport,
        companyId: variables.companyId ?? null,
      },
    });
  };

  return [updateServiceDocuments, mutationResult] as const;
}

export { useServiceDocumentsUpdater };
