import type { VehicleImageInput } from 'gql/graphql';

function fileFilter(imagesArray: (File | VehicleImageInput)[] = []) {
  return imagesArray.reduce<{
    files: Array<File>;
    previouslyUploadedImages: Array<VehicleImageInput>;
  }>(
    (acc, item) => {
      if (item instanceof File) {
        acc.files.push(item);
      } else {
        acc.previouslyUploadedImages.push(item);
      }

      return acc;
    },
    {
      files: [],
      previouslyUploadedImages: [],
    },
  );
}

export { fileFilter };
