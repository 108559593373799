import * as React from 'react';
import { useCarQualityInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/CarQuality/hooks/useProgress';
import { mapDataToFormValues } from 'pages/CarQuality/helpers';

function useCarQualityProgress(inspectionId: number) {
  const { data } = useCarQualityInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setCarQualityProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setCarQualityProgress(mapDataToFormValues(data?.inspection));
  }, [data, setCarQualityProgress]);

  React.useEffect(() => {
    setCarQualityProgress(mapDataToFormValues(data?.inspection));
  }, [data, setCarQualityProgress]);

  return updateProgress;
}

export { useCarQualityProgress };
