import React, { useCallback, useState } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

import { appRoutes } from 'appRoutes';
import type { InspectionSourceType } from 'gql/graphql';
import { inspectionSources, InspectionActions } from 'shared/constants';
import { requiredMutations } from 'shared/utils/route2ReqMutationsMap';
import { useTranslation } from 'shared/hooks/useTranslation';
import { PageLoader } from 'shared/components/PageLoader';
import { getInspectionIdFromUrl } from 'shared/utils/inspectionId';
import { RoutesEnum } from 'shared/utils/route';
import { useInspectionQuery } from 'gql/graphql';

type Progress = Partial<Record<keyof typeof RoutesEnum, number>>;
type Context = {
  sourceType: InspectionSourceType | null;
  isConfirmed: boolean;
  stockNumber: string | null;
  inspectionId: number;
  percentages: Progress;
  setProgress: (value: Progress) => void;
  isPdfInspection: boolean;
  notCompletedSteps: string[];
  setNotCompletedSteps: React.Dispatch<React.SetStateAction<string[]>>;
  removeStepFromNotCompleted: (routeEnum: keyof typeof RoutesEnum) => void;
};

const initialState = Object.freeze({
  sourceType: null,
  isConfirmed: false,
  stockNumber: null,
  inspectionId: 0,
  percentages: {},
  setProgress: () => {},
  isPdfInspection: false,
  notCompletedSteps: [],
  setNotCompletedSteps: () => {},
  removeStepFromNotCompleted: () => {},
});

const InspectionContext = React.createContext<Context>(initialState);

const InspectionProvider: React.FC = ({ children }) => {
  const { translations } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const inspectionId = getInspectionIdFromUrl(location.pathname) ?? 0;

  const [notCompletedSteps, setNotCompletedSteps] = useState<string[]>([]);
  const [percentages, setPercentages] = useState(initialState.percentages);

  const { data, loading } = useInspectionQuery({
    skip: !inspectionId,
    variables: {
      inspectionId,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    onCompleted: (response) => {
      let errorMessage = '';
      if (response?.inspection?.editable === false) {
        errorMessage = translations.EDITING_IS_NOT_POSSIBLE;
      }
      const canConvertToEva = response?.inspection?.actions?.find(
        ({ name }) => name === InspectionActions.ADD_TO_FULL_EVALUATION,
      );
      if (
        response?.inspection?.sourceType === inspectionSources.PI_APP &&
        !canConvertToEva
      ) {
        errorMessage = translations.INVALID_STOCK_NUMBER;
      }
      if (errorMessage) {
        history.push({
          pathname: appRoutes.errorMessagePage(true),
          state: {
            message: errorMessage,
          },
        });
      }
    },
  });

  const stockNumber = data?.inspection?.car?.stockNumber ?? null;
  const sourceType = data?.inspection?.sourceType ?? null;
  const isPdfInspection = (
    [
      inspectionSources.PDF_INSPECTION,
      inspectionSources.CATALOGUE_AUCTION,
    ] as (InspectionSourceType | null)[]
  ).includes(sourceType);

  const removeStepFromNotCompleted = useCallback(
    (routeEnum: keyof typeof RoutesEnum) => {
      const steps = requiredMutations(isPdfInspection)[routeEnum] ?? [];
      const newSteps = notCompletedSteps.filter(
        (step) => !steps.includes(step),
      );
      setNotCompletedSteps(newSteps);
    },
    [notCompletedSteps, isPdfInspection],
  );

  const renderChildren = () => {
    if (loading) {
      return <PageLoader message={translations.LOADING_INSPECTION_APP} />;
    }

    if (inspectionId === 0) {
      return <Redirect to={appRoutes.notFound()} />;
    }

    return <>{children}</>;
  };

  const setProgress = useCallback(
    (value: Partial<Record<keyof typeof RoutesEnum, number>>) =>
      setPercentages((prev) => ({ ...prev, ...value })),
    [],
  );

  return (
    <InspectionContext.Provider
      value={{
        inspectionId,
        stockNumber,
        sourceType,
        isConfirmed: stockNumber !== null,
        isPdfInspection,
        percentages,
        notCompletedSteps,
        setNotCompletedSteps,
        removeStepFromNotCompleted,
        setProgress,
      }}
    >
      {renderChildren()}
    </InspectionContext.Provider>
  );
};

const useInspection = () => {
  return React.useContext(InspectionContext);
};

export { InspectionContext, InspectionProvider, useInspection };
export type { Context };
