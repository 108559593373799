import * as React from 'react';
import cn from 'classnames';
import { Button, ButtonTypes, ButtonThemes } from '@auto1-ui/button';
import { Player, ControlBar, Shortcut } from 'video-react';
import { Dialog } from '@auto1-ui/dialog';
import { keyExtractor } from '../../utils/keyExtractor';
import 'video-react/dist/video-react.css';
import styles from './index.module.scss';
import './player.scss';

type Props = {
  videoFile: string;
  qaIdPrefix?: string;
  buttons?: ReadonlyArray<{
    onClick: () => void;
    label: string;
    theme?: ButtonThemes;
    alignment?: 'left' | 'right';
    type?: ButtonTypes;
  }>;
  handleClose: () => void;
  isOpen: boolean;
};

const VideoPreview: React.FC<Props> = ({
  videoFile,
  buttons = [],
  qaIdPrefix = 'video-preview',
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseIconVisible
      closeIconQaId={`${qaIdPrefix}-close-icon`}
    >
      <div
        className={cn(styles.videoPreview, {
          noButtons: buttons.length === 0,
        })}
      >
        <div
          className={cn(styles.content, 'video-player-dialog')}
          data-qa-id={qaIdPrefix}
        >
          <Player playsInline autoPlay src={videoFile}>
            <ControlBar
              autoHide={false}
              className={cn({
                hasButtons: !!buttons.length,
              })}
            />
            <Shortcut dblclickable={false} />
          </Player>
        </div>
        {buttons.length > 0 && (
          <div className={styles.footer}>
            <span className={styles.leftActionButtons}>
              {buttons
                .filter(
                  (button) => !button.alignment || button.alignment === 'left',
                )
                .map(({ theme, type, onClick, label, ...rest }, index) => (
                  <Button
                    qaId={`${qaIdPrefix}-left-btn-${index + 1}`}
                    theme={theme}
                    extraClass={styles.btn}
                    type={type ?? ButtonTypes.button}
                    onClick={onClick}
                    key={keyExtractor(rest, index)}
                  >
                    {label}
                  </Button>
                ))}
            </span>
            <span className={styles.rightActionButtons}>
              {buttons
                .filter((button) => button.alignment === 'right')
                .map(({ theme, type, onClick, label, ...rest }, index) => (
                  <Button
                    qaId={`${qaIdPrefix}-right-btn-${index + 1}`}
                    extraClass={styles.btn}
                    theme={theme}
                    type={type ?? ButtonTypes.button}
                    onClick={onClick}
                    key={keyExtractor(rest, index)}
                  >
                    {label}
                  </Button>
                ))}
            </span>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export { VideoPreview };
