import * as React from 'react';
import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'shared/hooks/useTranslation';
import { AlertBar } from 'shared/components/AlertBar';
import { FormUploader } from 'shared/components/FormUploader';
import { FormDropdown } from 'shared/components/FormDropdown';
import { FormCheckbox } from 'shared/components/FormCheckbox';
import { FormToggleButton } from 'shared/components/FormToggleButton';
import { FormNumberInput } from 'shared/components/FormNumberField';
import { Typography } from 'shared/components/Typography';
import { InputLabel } from 'shared/components/InputLabel';
import { Card } from 'shared/components/Card';
import { InfoInCircle } from 'shared/components/Icons';
import { processHeicFile } from 'shared/components/FormUploader/processHeicFile';
import styles from './index.module.scss';
import { booleanOptions, batteryHealthOptions } from './options';

type Props = {
  qaIdPrefix: string;
  isPdfInspection?: boolean;
};

const getFieldName = (name: string) => `electricCar.${name}`;

const infoIconProps = (label: string) => ({
  'data-microtip-position': 'top',
  'aria-label': label.replace(/<br>|<br\/>|<br \/>|\\n /g, '\u000A'),
  role: 'tooltip',
});

const ElectricCarInfo: React.FC<Props> = ({
  qaIdPrefix,
  isPdfInspection = false,
}) => {
  const {
    setValue,
    watch,
    register,
    unregister,
    trigger,
    formState: { errors: formErrors, isSubmitted },
  } = useFormContext();
  const { translations } = useTranslation();
  const errors = formErrors?.electricCar ?? {};

  React.useEffect(() => {
    register('fuelType');

    return () => {
      unregister('fuelType');
    };
  }, [register, unregister]);

  const fuelType = watch('fuelType');
  const isBatteryLeased = watch(getFieldName('batteryLeased'));
  const isBatteryLeasingCostNotAvailable = watch(
    getFieldName('isBatteryLeasingCostNotAvailable'),
  );
  const isBatteryConditionReportAvailable = watch(
    getFieldName('isBatteryConditionReportAvailable'),
  );

  React.useEffect(() => {
    if (isBatteryLeasingCostNotAvailable) {
      setValue(getFieldName('batteryLeasingCost'), null);
    }
  }, [isBatteryLeasingCostNotAvailable, setValue]);

  return (
    <Card
      paddingTop={32}
      showBottomBorder
      qaIdPrefix={qaIdPrefix}
      id="electricCarInfo"
    >
      <Typography variant="titleXL" data-qa-id={`${qaIdPrefix}-title`}>
        {translations.ELECTRIC_CAR_INFO}
      </Typography>
      {isPdfInspection && (
        <AlertBar
          message={
            Object.keys(errors).length > 0
              ? translations.ELECTRIC_CAR_PDF_DISCLAIMER
              : translations.ELECTRIC_CAR_PDF_VALIDATION_DISCLAIMER
          }
          type={Object.keys(errors).length > 0 ? 'error' : 'info'}
          additionalClassNames={styles.subtitle}
        />
      )}
      <div className={styles.electricCarSection}>
        <div className={styles.fieldGroup}>
          {/* Battery leased */}
          <FormToggleButton
            name={getFieldName('batteryLeased')}
            label={translations.BATTERY_LEASED}
            containerClassName={styles.toggleButton}
            errorClassName={styles.toggleError}
            qaIdPrefix={`${qaIdPrefix}-battery-leased`}
            options={booleanOptions(translations)}
            defaultValue={null}
          />

          {/* Current leasing costs */}
          {isBatteryLeased && (
            <div className={styles.containerDropdown}>
              <FormNumberInput
                label={translations.CURRENT_LEASING_COST}
                name={getFieldName('batteryLeasingCost')}
                className={cn(styles.input, styles.currentLeasingCostInput)}
                qaIdPrefix={`${qaIdPrefix}-current-leasing-costs`}
                disabled={isBatteryLeasingCostNotAvailable}
                defaultValue={null}
              />
              <FormCheckbox
                name={getFieldName('isBatteryLeasingCostNotAvailable')}
                uiLabel={translations.NOT_AVALIABLE}
                qaIdPrefix={`${qaIdPrefix}-current-leasing-available`}
                containerClassName={styles.checkBoxLabel}
                defaultValue={false}
                afterChange={() => {
                  if (isSubmitted) {
                    trigger(getFieldName('batteryLeasingCost'));
                  }
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.fieldGroup}>
          {/* Battery conditions report */}
          <FormToggleButton
            name={getFieldName('isBatteryConditionReportAvailable')}
            label={
              <>
                {translations.BATTERY_CONDITIONS_REPORT_AVAILABLE}
                <span
                  className={styles.iconButton}
                  {...infoIconProps(
                    translations.BATTERY_CONDITIONS_REPORT_AVAILABLE_INFO,
                  )}
                >
                  <InfoInCircle width={16} height={16} />
                </span>
              </>
            }
            containerClassName={styles.toggleButton}
            errorClassName={styles.toggleError}
            qaIdPrefix={`${qaIdPrefix}-battery-conditions-report`}
            options={booleanOptions(translations)}
            defaultValue={null}
          />
          {/* Battery conditions status */}
          {isBatteryConditionReportAvailable && (
            <div className={styles.containerDropdown}>
              <FormDropdown
                label={
                  <>
                    {translations.BATTERY_CONDITION_STATUS}
                    <span
                      className={styles.iconButton}
                      {...infoIconProps(
                        translations.BATTERY_CONDITION_STATUS_INFO,
                      )}
                    >
                      <InfoInCircle width={16} height={16} />
                    </span>
                  </>
                }
                qaIdPrefix={`${qaIdPrefix}-battery-health`}
                name={getFieldName('batteryHealth')}
                options={batteryHealthOptions}
                className={styles.input}
                searchable
                defaultValue={null}
              />
            </div>
          )}
        </div>
        <div className={styles.fieldGroup}>
          {/* Chargin cables */}
          <FormToggleButton
            name={getFieldName('hasChargingCables')}
            label={translations.CHARGING_CABLES_AVAILABLE}
            containerClassName={styles.toggleButton}
            errorClassName={styles.toggleError}
            qaIdPrefix={`${qaIdPrefix}-has-charging-cables`}
            options={booleanOptions(translations)}
            defaultValue={null}
          />
        </div>
      </div>
      {/* Battery charging level */}
      {fuelType === 'ELECTRIC' && (
        <FormCheckbox
          name={getFieldName('isBatteryChargeLevelGuaranteed')}
          uiLabel={translations.IS_BATTERY_CHARGE_LEVEL_GUARANTEED}
          qaIdPrefix={`${qaIdPrefix}-is-battery-charge-level-guaranteed`}
          containerClassName={cn(
            styles.checkBoxLabel,
            styles.batteryChargeLevelContainer,
          )}
          defaultValue={false}
        />
      )}
      <div className={styles.uploaderWrapper}>
        <InputLabel>
          {/* Supporting documents */}
          {translations.SUPPORTING_DOCUMENTS}{' '}
          <span className={styles.optionalLabel}>
            {' '}
            {translations.IF_AVALIABLE}
          </span>
        </InputLabel>

        <FormUploader
          allowMultipleFiles
          uploadFileTypes="image/png, image/jpeg, image/heic"
          processSelectedFile={processHeicFile}
          extraClassName={styles.uploaderStyles}
          name={getFieldName('supportingDocuments')}
          qaIdPrefix={`${qaIdPrefix}-supporting-docs-images`}
        >
          {({ handleUploadClick }) => (
            <p>
              {translations.DROP_PHOTOS_HERE}{' '}
              <button
                type="button"
                className={styles.browseButton}
                onClick={(event) => {
                  event.preventDefault();
                  handleUploadClick();
                }}
              >
                {translations.BROWSE}
              </button>
            </p>
          )}
        </FormUploader>
      </div>
    </Card>
  );
};

export { ElectricCarInfo };
