import * as React from 'react';
import { Button, ButtonThemes, ButtonTypes } from '@auto1-ui/button';
import { Dialog } from '@auto1-ui/dialog';
import styles from './index.module.scss';

type ButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  label: string;
  theme: ButtonThemes;
  alignment: 'left' | 'right';
  type?: ButtonTypes;
};

type Props = {
  imageSrc: string;
  qaIdPrefix?: string;
  showLoader?: boolean;
  buttons?: ReadonlyArray<ButtonProps>;
  handleClose: () => void;
  isOpen: boolean;
};

const ImagePreview: React.FC<Props> = ({
  imageSrc,
  buttons = [],
  qaIdPrefix = 'image-preview',
  showLoader = false,
  isOpen,
  handleClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
      isCloseIconVisible
      closeIconQaId={`${qaIdPrefix}-close-icon`}
    >
      <>
        <div className={styles.imagePreview}>
          <div className={styles.content}>
            <img
              src={imageSrc}
              className={styles.previewImg}
              alt="preview"
              data-qa-id={qaIdPrefix}
            />
            {showLoader && (
              <div
                className={styles.overlay}
                data-qa-id={`${qaIdPrefix}-loader`}
              >
                {/*  todo: put loader gif here */}
              </div>
            )}
          </div>
          {buttons.length > 0 && (
            <div className={styles.footer}>
              <span className={styles.buttonGroup}>
                {buttons
                  .filter(
                    (button) =>
                      !button.alignment || button.alignment === 'left',
                  )
                  .map(({ theme, type, onClick, label }, index) => (
                    <Button
                      qaId={`${qaIdPrefix}-left-btn-${index + 1}`}
                      key={`${qaIdPrefix}-left-btn-${index + 1}`}
                      theme={theme}
                      extraClass={styles.btn}
                      type={type}
                      onClick={onClick}
                    >
                      {label}
                    </Button>
                  ))}
              </span>
              <span className={styles.buttonGroup}>
                {buttons
                  .filter((button) => button.alignment === 'right')
                  .map(({ theme, type, onClick, label }, index) => (
                    <Button
                      qaId={`${qaIdPrefix}-right-btn-${index + 1}`}
                      key={`${qaIdPrefix}-right-btn-${index + 1}`}
                      theme={theme}
                      extraClass={styles.btn}
                      type={type}
                      onClick={onClick}
                    >
                      {label}
                    </Button>
                  ))}
              </span>
            </div>
          )}
        </div>
      </>
    </Dialog>
  );
};

export { ImagePreview };
