import { pipe } from 'lodash/fp';

type Translate = (translationKey: string) => string;

const carDetailsTranslations = (translate: Translate) => {
  return {
    VIN_INFO: translate('vin-info'),
    FILL_MANUALLY: translate('fill-manually'),
    EDIT_DATA: translate('edit-data'),
    LOAD_VIN_DATA: translate('load-vin-data'),
    LOAD_DATA: translate('load-data'),
    MAKE: translate('manufacturer'),
    MAIN_TYPE: translate('main-type'),
    BUILT_YEAR: translate('built-year'),
    BODY_TYPE: translate('body-type'),
    SUBTYPE: translate('subtype'),
    MODEL: translate('model'),
    CAR_INFO: translate('car-info'),
    CYLINDERS: translate('cylinders'),
    CCM: translate('ccm'),
    GEAR: translate('gear'),
    MOTOR_ENGINE: translate('motor-engine'),
    DRIVE_TYPE: translate('drive-type'),
    FUEL_TYPE: translate('fuel-type'),
    REPORT_WRONG_DATA: translate('report-wrong-data'),
    ADDITIONAL_INFO: translate('additional-info'),
    ELECTRIC_CAR_INFO: translate('electric-car-info'),
    COUNTRY_OF_ORIGIN: translate('country-of-origin'),
    REGISTRATION_COUNTRY: translate('registration-country'),
    TOTAL_OWENRS: translate('total-owners'),
    NOT_AVALIABLE: translate('not-available'),
    INVENTORY_TYPE: translate('inventory-type'),
    ENVIRONMENTAL_CLASS: translate('env-class'),
    LICENSE_PLATE: translate('license-plate'),
    INTERNAL_NUMBER: translate('internal-number'),
    CO2_EMISSION: translate('co2-emission'),
    NO_CO2_EMISSION: translate('no-co2-emission'),
    VEHICLE_TITLE: translate('vehicle-title'),
    VAT_TYPE: translate('vat-type'),
    CURRENT_OWNER_TYPE_LABEL: translate('current-owner-type-label'),
    CAR_IS_FINANCED: translate('car-is-financed'),
    CAR_REGISTERATION_DOCUMENTS: translate('car-registration-documents'),
    COC_PICTURES: translate('coc-pictures'),
    DROP_PHOTOS_HERE: translate('drop-photos-here'),
    BROWSE: translate('browse'),
    IF_AVALIABLE: translate('if-available'),
    TRADE_IN: translate('trade-in'),
    LEASING: translate('leasing'),
    RENTAL: translate('rental'),
    NEW: translate('new'),
    DAMAGED: translate('damaged'),
    FLEET: translate('fleet'),
    OTHER: translate('Other'),
    DRIVING_SCHOOL: translate('driving-school'),
    NOT_IN_COMMERCIAL_USE: translate('not-in-commercial-use'),
    TAXI: translate('taxi'),
    AUTHORITIES: translate('authorities'),
    COMPANY_CAR: translate('company-car'),
    NOT_DEDUCTIBLE: translate('not-deductible'),
    DEDUCTIBLE: translate('deductible'),
    CURRENT_OWNER_TYPE_PRIVATE: translate('current-owner-type-private'),
    CURRENT_OWNER_TYPE_COMPANY: translate('current-owner-type-company'),
    CURRENT_OWNER_TYPE_UNKNOWN: translate('current-owner-type-unknown'),
    PDF_INFO_POPULATED_SUCCESSFULLY: translate(
      'pdf-info-populated-successfully',
    ),
    VIN_IS_OPTIONAL_DISCLAIMER: translate('vin-is-optional-disclaimer'),
    PDF_INSPECTION_REPORT_HINT: translate('pdf-inspection-report-hint'),
    PDF_INSPECTION_PARSING_TIMEOUT: translate('pdf-inspection-parsing-timeout'),
    PDF_LABEL: translate('pdf-label'),
    LOAD_DATA_SERVER_ERROR: translate('load-data-server-error'),
    BATTERY_LEASED: translate('battery-leased'),
    CURRENT_LEASING_COST: translate('current-leasing-cost'),
    BATTERY_CONDITIONS_REPORT_AVAILABLE: translate(
      'battery-conditions-report-available',
    ),
    BATTERY_CONDITIONS_REPORT_AVAILABLE_INFO: translate(
      'battery-conditions-report-available-info',
    ),
    BATTERY_CONDITION_STATUS: translate('battery-conditions-status'),
    BATTERY_CONDITION_STATUS_INFO: translate('battery-conditions-status-info'),
    ELECTRIC_ENGINE_BATTERY_CAPACITY: translate(
      'electric-engine-battery-capacity',
    ),
    CHARGING_CABLES_AVAILABLE: translate('charging-cables-available'),
    SUPPORTING_DOCUMENTS: translate('supporting-documents'),
    SUBMIT_ECODE_BUTTON_TOOLTIP: translate('submit-ecode-button-tooltip'),
    CAR_DETAILS_LICENSE_PLATE_FAIL_MESSAGE: translate(
      'car-details-license-plate-fail-message',
    ),
    ELECTRIC_CAR_PDF_DISCLAIMER: translate('electric-car-pdf-disclaimer'),
    ELECTRIC_CAR_PDF_VALIDATION_DISCLAIMER: translate(
      'electric-car-pdf-validation-disclaimer',
    ),
    IS_BATTERY_CHARGE_LEVEL_GUARANTEED: translate(
      'is-battery-charge-level-guaranteed',
    ),
  };
};

const carPictures = (translate: Translate) => {
  return {
    PLEASE_UPLOAD_ALL_PICTURES: translate('Please-upload-all-car-picture'),
    CAR_IMAGES_PDF_DISCLAIMER: translate('car-images-pdf-disclaimer'),
    CAR_IMAGES_PDF_VALIDATION_DISCLAIMER: translate(
      'car-images-pdf-validation-disclaimer',
    ),
    INSIDE: translate('inside'),
    OUTSIDE: translate('outside'),
    OPTIONAL_PICTURES_AND_VIDEO: translate('optional-pictures-and-video'),
    SPEEDOMETER_WITH_STARTED_ENGINE: translate(
      'speedoometer-with-started-engine',
    ),
    INTERIOR_BACK: translate('interior-back'),
    INTERIOR_FRONT: translate('interior-front'),
    CENTER_CONSOLE: translate('center-console'),
    FRONT: translate('front'),
    ENGINE_COMPARTMENT: translate('engine-compartment'),
    LEFT_SIDE_FRONT: translate('left-side-front'),
    LEFT_SIDE_BACK: translate('left-side-back'),
    LEFT_FRONT: translate('left-front'),
    BACK: translate('back'),
    RIGHT_SIDE_BACK: translate('right-side-back'),
    RIGHT_SIDE_FRONT: translate('right-side-front'),
    NAVIGATION: translate('navigation'),
    TRUNK: translate('trunk'),
    ENGINE_VIDEO: translate('engine-video'),
    OTHER_PICTURES: translate('other-pictures'),
    CAN_UPLOAD_MULTIPLE_PICTURES: translate('can-upload-multiple-pictures'),
    FREE_UPLOAD: translate('free-upload'),
  };
};

const carQuality = (translate: Translate) => {
  return {
    DPF_FAP: translate('dpf-fap'),
    ON_BOARD_COMPUTER: translate('on-board-computer-in-place'),
    POWER_WINDOWS: translate('power-windows'),
    ESP_IN_PLACE: translate('esp-in-place'),
    ABS_IN_PLACE: translate('abs-in-place'),
  };
};

const sideBarTranslations = (translate: Translate) => {
  return {
    CAR_DETAILS: translate('car-details'),
    VIN_INFO: translate('vin-info'),
    CAR_INFO: translate('car-info'),
    ADDITIONAL_INFO: translate('additional-info'),
    ELECTRIC_CAR_INFO: translate('electric-car-info'),
    TEST_DRIVE: translate('test-drive'),
    MILEAGE: translate('mileage'),
    FINDINGS: translate('findings'),
    CAR_PICTURES: translate('car-pictures'),
    MAIN_CAR_PHOTOS: translate('main-car-photos'),
    ADDITIONAL_PHOTOS: translate('additional-photos'),
    CAR_QUALITY: translate('car-quality'),
    THICKNESS: translate('thickness'),
    DAMAGES: translate('damages'),
    HIGHLIGHTS: translate('highlights'),
    TIRES_AND_BRAKES: translate('tires-and-breaks'),
    CAR_STATUS: translate('car-status'),
    EQUIPMENT: translate('equipment'),
    OTHER_EQUIPMENT: translate('other-equipment'),
    OTHER_INFORMATION: translate('other-information'),
    SERVICE_AND_DOCUMENTS: translate('service-and-documents'),
    SERVICE: translate('service'),
    ACCIDENTS: translate('accidents'),
    OTHER_DOCUMENTS: translate('other-documents'),
    CAR_INSPECTION: translate('car-inspection'),
  };
};

const CompletedPage = (translate: Translate) => {
  return {
    CAR_INSPECTION_COMPLETED: translate('car-inspection-completed'),
    CAR_IS_BEING_REVIEWED: translate('car-is-currently-being-reviewd'),
    GO_TO_DASHBOARD: translate('go-to-dashboard'),
    INSPECT_ANOTHER_CAR: translate('inspect-another-car'),
  };
};

const Inspectionslist = (translate: Translate) => {
  return {
    INSPECTIONS: translate('inspections'),
    SEARCH_BY_STOCK_NUMBER: translate('search-by-stock-number'),
    INSPECT_CAR: translate('inspect-car'),
    PDF_EVALUATION: translate('pdf-evaluation'),
    LOADING_INSPECTIONS_LIST: translate('loading-inspections-list'),
  };
};

const upholsteryOptions = (translate: Translate) => {
  return {
    ALCANTARA: translate('alcantara'),
    CLOTH: translate('cloth'),
    LEATHER: translate('leather'),
    PART_LEATHER: translate('Part-leather'),
    SYNTHETIC_LEATHER: translate('synthetic-leather'),
    PLASTIC: translate('plastic'),
    VELOUR: translate('velour'),
    OTHER: translate('other'),
  };
};

const ServiceDocuments = (translate: Translate) => {
  return {
    SERVICE_HEADER: translate('service-header'),
    SERVICE_PDF_DISCLAIMER: translate('service-pdf-disclaimer'),
    SERVICE_PDF_VALIDATION_DISCLAIMER: translate(
      'service-pdf-validation-disclaimer',
    ),
    SERVICE_QUESTION_SERVICE_BOOK_AVAILABLE: translate(
      'service-question-serviceBookAvailable',
    ),
    SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_PAPER: translate(
      'service-answer-serviceBookAvailable-paper',
    ),
    SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_DIGITAL: translate(
      'service-answer-serviceBookAvailable-digital',
    ),
    SERVICE_ANSWER_SERVICE_BOOK_AVAILABLE_UNAVAILABLE: translate(
      'service-answer-serviceBookAvailable-unavailable',
    ),
    SERVICE_QUESTION_SERVICE_PHOTOS: translate(
      'service-question-serviceBookPhotos',
    ),
    SERVICE_QUESTION_SERVICE_APPOINTMENTS: translate(
      'service-question-serviceBookAppointments',
    ),
    SERVICE_ANSWER_SERVICE_APPOINTMENTS_ALL: translate(
      'service-question-serviceBookAppointments-all',
    ),
    SERVICE_ANSWER_SERVICE_APPOINTMENTS_SOME: translate(
      'service-question-serviceBookAppointments-some',
    ),
    SERVICE_ANSWER_SERVICE_APPOINTMENTS_NONE: translate(
      'service-question-serviceBookAppointments-none',
    ),
    SERVICE_ANSWER_SERVICE_APPOINTMENTS_NOT_DUE: translate(
      'service-question-serviceBookAppointments-notDue',
    ),
    SERVICE_QUESTION_LAST_SERVICE_DATE: translate(
      'service-question-lastServiceDate',
    ),
    SERVICE_QUESTION_LAST_SERVICE_MILEAGE: translate(
      'service-question-lastServiceMileage',
    ),
    SERVICE_QUESTION_DRIVER_BELT_CHANGE: translate(
      'service-question-driverBeltChanged',
    ),
    SERVICE_QUESTION_DRIVER_BELT_CHANGE_DATE: translate(
      'service-question-driverBeltChangeDate',
    ),
    SERVICE_QUESTION_DRIVER_BELT_CHANGE_MILEAGE: translate(
      'service-question-driverBeltChangeMileage',
    ),
    SERVICE_QUESTION_ORIGINAL_ENGINE: translate(
      'service-question-originalEngine',
    ),
    SERVICE_QUESTION_ENGINE_CHANGE_MILEAGE: translate(
      'service-question-engineChangeMileage',
    ),
    SERVICE_QUESTION_MILEAGE_MANIPULATION: translate(
      'service-question-mileageManipulation',
    ),
    SERVICE_QUESTION_MILEAGE_MANIPULATION_REASON: translate(
      'service-question-mileageManipulationReason',
    ),
    SERVICE_QUESTION_ROADWORTHY: translate('service-question-roadworthy'),
    SERVICE_QUESTION_ROLLABLE: translate('service-question-rollable'),
    SERVICE_QUESTION_ROLLABLE_DESCRIPTION: translate(
      'service-question-rollable-description',
    ),
    SERVICE_ANSWER_ROADWORTHY_TOTAL_LOSS: translate(
      'service-answer-roadworthy-totalLoss',
    ),
    SERVICE_QUESTION_INSPECTION_EXPIRY_DATE: translate(
      'service-question-inspectionExpiryDate',
    ),
    ACCIDENT_HEADER: translate('accident-header'),
    ACCIDENT_QUESTION_HAD_ACCIDENT: translate('accident-question-hadAccident'),
    ACCIDENT_QUESTION_REPAIRED: translate('accident-question-repaired'),
    ACCIDENT_QUESTION_REPAIRER: translate('accident-question-repairer'),
    OEM_FRANCHISE_DEALERSHIP: translate('repairer-oem'),
    REPAIRER_INDEPENDENT: translate('repairer-independent'),
    REPAIRER_UNKNOWN: translate('repairer-unknown'),
    ACCIDENT_QUESTION_COST: translate('accident-question-cost'),
    ACCIDENT_QUESTION_INVOICE_PHOTOS: translate(
      'accident-question-invoicePhotos',
    ),
    SERVICE_OTHER_DOCUMENT_HEADER: translate('service-otherDocs-header'),
    SERVICE_OTHER_DOCUMENT_QUESTION_NOTES: translate(
      'service-otherDocs-question-notes',
    ),
    PDF_INSPECTION_REPORT: translate('pdf-inspection-report'),
    DROP_PDF_HERE: translate('drop-pdf-here'),
    UPLOADING_PDF: translate('uploading-pdf'),
    SERVICE_OTHER_DOCUMENT_INSPECTION_COMPANY: translate(
      'technical-inspection-company',
    ),
  };
};

const Validation = (translate: Translate) => {
  return {
    THIS_FIELD_CANNOT_BE_EMPTY: translate('this-field-cannot-be-empty'),
    CHOOSE_ONE_OPTION: translate('Choose-one-option'),
    FILL_FIELD: translate('fill-the-field-or-select-not-available'),
    AT_LEAST_ONE_FILE_IS_REQUIRED: translate('at-least-one-file-is-required'),
    PLEASE_CHOOSE_FILE: translate('please-choose-a-file'),
    FIELD_AT_LEAST: translate('field-at-least'),
    NOT_VALID_DATE: translate('not-valid-date'),
    NOT_VALID_MAIL: translate('not-valid-email'),
    DATE_NOT_IN_FUTURE: translate('date-not-in-future'),
    NOT_BEFORE_DATE: translate('not-before-date'),
    PLEASE_DO_NOT_USE_THIS_LETTERS: translate('please-do-not-use-this-letters'),
    PROVIDE_A_NUMBER: translate('provide-a-number'),
    INVALID_DOT_FORMAT: translate('invalid-dot-format'),
    INVALID_DOT_LENGTH: translate('invalid-dot-length'),
    NOT_IN_FUTURE: translate('not-in-future'),
    GREATER_THAN_ZERO: translate('greater-than-zero'),
    AFTER_BUILD_DATE: translate('after-build-date'),
    LENGTH: translate('field-has-to-have-length'),
    INVALID_LENGTH: translate('invalid-length'),
  };
};

const LocationsPage = (translate: Translate) => {
  return {
    ADD_LOCATION_TITLE: translate('add-location-title'),
    EDIT_LOCATION_TITLE: translate('edit-location-title'),
    BACK_TO_LIST: translate('back-to-list'),
    LOCATION_NAME: translate('location-name'),
    ADDRESS: translate('address'),
    EMAIL: translate('email'),
    STREET: translate('street'),
    PHONE: translate('phone'),
    STREETNUMBER: translate('street-number'),
    ZIPCODE: translate('zipcode'),
    CITY: translate('city'),
    COUNTRY: translate('country'),
    SET_AS_DEFAULT_BRANCH: translate('set-as-default-branch'),
    ADD_LOCATION_BTN: translate('add-location-button'),
    EDIT_LOCATION_BTN: translate('edit-location-btn'),
    WAS_SUCESSFULLY_CREATED: translate('was-sucessfully-created'),
    PHONE_NUMBER_IS_NOT_VALID: translate('phone-number-is-not-valid'),
  };
};

const AutoPrice = (translate: Translate) => {
  return {
    AUTOPRICE_MODAL_LOADING: translate('autoprice-modal-loading'),
    AUTOPRICE_MODAL_OUR_SUGGESTION: translate('autoprice-modal-our-suggestion'),
    AUTOPRICE_MODAL_MARKET_PRICE: translate('autoprice-modal-market-price'),
    AUTOPRICE_MODAL_NOTE: translate('autoprice-modal-note'),
  };
};

const BranchesPagination = (translate: Translate) => {
  return {
    BRANCH_NOT_FOUND: translate('branch-not-found'),
    LOADING_BRANCHES: translate('loading-branches'),
    LOAD_MORE_BRANCHES: translate('load-more-branches'),
  };
};

const TranslationEnum = (translatorFunction: Translate) => {
  const PREFIX = 'a1-inspectionApp';
  const buildTranslationKey = (key: string) => `${PREFIX}-${key}`;

  const translate = pipe(buildTranslationKey, translatorFunction);

  return Object.freeze({
    BACK_BUTTON: translate('back-button'),
    NEXT: translate('next'),
    SEARCH: translate('search'),
    YES: translate('yes'),
    SHOW_MORE: translate('show-more'),
    SHOW_LESS: translate('show-less'),
    NO: translate('no'),
    REPLACE: translate('replace'),
    BRANCH: translate('branch'),
    YOU_HAVE_NO_REGISTERED_LOCATION: translate(
      'you-have-no-registered-location',
    ),
    ADD_LOCATION_LINK: translate('add-location-link'),
    CANCEL: translate('cancel'),
    ARE_YOU_SURE_YOU_WANT_TO_DELETE: translate(
      'are-you-sure-you-want-to-delete-this-item',
    ),
    OPTIONAL: translate('optional'),
    DELETE: translate('delete'),
    NOT_PRESENT: translate('not-present'),
    LEAVE: translate('leave'),
    LOADING_INSPECTION_APP: translate('loading-inspection-app'),
    LOADING_BRANCHES_PAGE: translate('loading-branches-page'),
    GENERIC_ERROR_MESSAGE: translate('generic-error-message'),
    CAR_PICTURE_HEIC_CONVERSION_ERROR: translate(
      'car-picture-heic-conversion-error',
    ),

    // Login page
    LOGIN_TITLE: translate('login-title'),
    LOGIN_SUBTITLE: translate('login-subtitle'),
    LOGIN_BTN: translate('google-login-btn'),

    UNFINISHED: translate('unfinished'),
    FAILED: translate('failed'),
    PI_UNFINISHED: translate('unfinished'),
    WAITING_A1_PRICE_INDICATOR: translate('price-indicator-pending'),
    A1_PRICE_INDICATOR_GIVEN: translate('price-indicator-set'),

    // Car info
    ALL_INSPECTIONS: translate('all-inspections'),
    LOAD_CAR_DATA: translate('load-car-data'),
    DO_NOT_HAVE_LICENSE_PLATE: translate('do-not-have-license-plate'),
    USE_VIN_INSTEAD: translate('use-vin-instead'),
    LICENSE_PLATE_FAIL_MESSAGE: translate('license-plate-fail-message'),

    // Test Drive
    TEST_DRIVE_PDF_DISCLAIMER: translate('test-drive-pdf-disclaimer'),
    TEST_DRIVE_PDF_VALIDATION_DISCLAIMER: translate(
      'test-drive-pdf-validation-disclaimer',
    ),

    // Tires and Brakes
    INSTALLED_WHEELS: translate('installed-wheels'),
    APPLY_ALL_WHEELS: translate('apply-all-wheels'),
    EXTRA_WHEEL_TITLE: translate('extra-wheel-title'),
    ADD_EXTRA_WHEELS: translate('add-extra-wheels'),
    FRONT_LEFT_WHEEL: translate('front-left-wheel'),
    FRONT_RIGHT_WHEEL: translate('front-right-wheel'),
    REAR_LEFT_WHEEL: translate('rear-left-wheel'),
    REAR_RIGHT_WHEEL: translate('rear-right-wheel'),
    PROFILE_DEPTH: translate('profile-depth'),
    BRAKES_CONDITION: translate('brakes-conditions'),
    TYRE_TYPE: translate('tyre-type'),
    RIM_TYPE: translate('rim-type'),
    DOT: translate('dot'),
    AS_GOOD_AS_NEW: translate('as-good-as-new'),
    BURR: translate('burr'),
    MORE_THAN_50: translate('more-than-50m'),
    LESS_THAN_50: translate('less-than-50m'),
    WEAR_LIMIT: translate('wear-limit'),
    BAD_SUPPORTING_BRAKE: translate('bad-supporting-brake'),
    DRUM_BRAKE: translate('dum-brake'),
    CARBON_CERAMIC_BRAKES: translate('carbon-ceramic-brakes'),
    ALL_SEASON: translate('all-season'),
    OFFROAD: translate('off-road'),
    PERFORMANCE: translate('performance'),
    SLICK: translate('slick'),
    SUMMER: translate('summer'),
    WINTER: translate('winter'),
    EMERGENCY: translate('emergency'),
    WITHOUT_RIMS: translate('without-rims'),
    ALUMINIUM_ORIGINAL: translate('aluminium-original'),
    ALUMINIUM_SUPPLIER: translate('aluminium-supplier'),
    ALUMINIUM: translate('aluminium'),
    CAROB: translate('carbon'),
    MAGNESIUM: translate('magnesium'),
    UNKNOWN: translate('unknown'),
    STEEL: translate('steel'),
    STEEL_WITH_COVER: translate('steel-with-cover'),
    TIRES_BRAKES_PDF_DISCLAIMER: translate('tires-brakes-pdf-disclaimer'),
    TIRES_BRAKES_PDF_VALIDATION_DISCLAIMER: translate(
      'tires-brakes-pdf-validation-disclaimer',
    ),

    // Car Status
    EQUIPMENT_TITLE: translate('equipment-title'),
    POWER_STEERING: translate('power-steering'),
    AIRBAG: translate('airbag'),
    HEATED_SEATS: translate('heated-seats'),
    PARK_DISTANCE_CONTROL: translate('park-distance-control'),
    ENTERTAINMENT_SYSTEM: translate('entertainment-system'),
    AC_SYSTEM: translate('ac-system'),
    XENON_LIGHTS: translate('xenon-lights'),
    EQUIPMENTS_CANNOT_BE_SHOWN: translate('equipments-cannot-be-shown'),
    // Summary keys
    SUMMARY: translate('summary'),
    CAR_DETAILS_TITLE: translate('car-details-title'),
    CAR_PICTURES_TITLE: translate('car-pictures-title'),
    FAILURE_REASONS_TITLE: translate('failure-reasons-title'),
    CONDITIONAL_AND_TECHNICAL_DETAILS_TITLE: translate(
      'conditional-and-technical-details-title',
    ),
    SERVICE_HISTORY_TITLE: translate('service-history-title'),
    SAVE: translate('save'),
    CONFIRM_DATA: translate('confirm-data'),
    VIN: translate('vin'),
    FIRST_REGISTRATION_DATE: translate('first-registration-date'),
    FIRST_REGISTRATION_DATE_SUMMARY: translate(
      'first-registration-date-summary',
    ),
    MANUFACTURER: translate('manufacturer'),
    COUNTRY_OF_REGISTRATION: translate('country-of-registration'),
    VEHICLE_MODEL: translate('vehicle-model'),
    DEREGISTRATION_DATE: translate('deregistration-date'),
    KW_HP: translate('kw-hp'),
    KEYS_NUMBER: translate('keys-amount'),
    TRANSMITION_TYPE: translate('gear'),
    NEXT_INSPECTION_DUE_ON: translate('service-question-inspectionExpiryDate'),
    MILEAGE_KM: translate('mileage-km'),
    USAGE_TYPE: translate('usage-type'),
    TOTAL_OWNERS: translate('total-owners'),
    BATTERY_LEASED_SUMMARY: translate('battery-leased-summary'),
    BATTERY_CONDITIONS_REPORT_AVAILABLE_SUMMARY: translate(
      'battery-conditions-report-available-summary',
    ),
    ACCIDENT_CAR: translate('accident-car'),
    UPHOLSTERY: translate('upholstery'),
    ENGINE_CONDITION: translate('engine-condition'),
    AIR_CONDITIONING: translate('air-conditionion'),
    SERVICE_APPOINTMENT: translate('service-appointment'),
    LAST_SERVICE_MILEAGE_KM: translate('service-question-lastServiceMileage'),
    LAST_SERVICE_DATE: translate('service-question-lastServiceDate'),
    SUBMIT_INSPECTION: translate('submit-inspection'),
    SUBMIT_24_AUCTION: translate('submit-24-auction'),
    SEND_TO_24H_AUCTION: translate('send-to-24h-auction'),
    PLEASE_ACCEPT_TERMS: translate('please-accept-terms'),
    EXPECTED_PRICE_24_AUCTION: translate('expected-price-for-24h-auction'),
    EXPECTED_PRICE: translate('expected-price'),
    AUTO1_PRICE_INDICATOR: translate('auto1-price-indicator'),
    PRICE_ADVISE: translate('price-advise'),
    INCOMPLETE_INSPECTION: translate('incomplete-inspection'),
    INSPECTION_ALREADY_SUBMITTED: translate('already-submitted-inspection'),
    INCOMPLETE_MUTATION_NOT_ALLOWED: translate(
      'incomplete-inspection-not-allowed',
    ),
    VALIDATE_AND_CONFIRM_INFORMATION: translate(
      'summary-validate-confirm-information',
    ),
    CURRENT_MILEAGE: translate('current-mileage'),
    MILEAGE_ODOMETER_BROKEN: translate('mileage-odometer-broken'),
    MILEAGE_CONFLICTING_SERVICE_HISTORY: translate(
      'mileage-conflicting-service-history',
    ),
    MILEAGE_CONDITION_SUSPICIOUS: translate('mileage-condition-suspicious'),
    MILEAGE_CONFLICTING_DATABASE: translate('mileage-conflicting-database'),
    MILEAGE_INSTRUMENT_REPLACED: translate('mileage-instrument-replaced'),
    MILEAGE_AT_REPLACEMENT: translate('mileage-at-replacement'),
    ...carDetailsTranslations(translate),
    ...carPictures(translate),
    GET_INSTANT_PRICE: translate('get-instant-price'),
    GET_INSTANT_PRICE_TOOLTIP: translate('get-instant-price-tooltip'),
    SHOULD_SELECT_ALL_FAILURE_REASONS: translate(
      'should-select-failure-reasons',
    ),
    GET_INSTANT_OFFER_ERROR: translate('get-instant-offer-error'),
    CHECKOUT_TYPE_ERROR: translate('checkout-type-error'),
    // Car quality
    QUALITY_AREA: translate('quality-area'),
    QUALITY_PART: translate('quality-part'),
    QUALITY_DAMAGE: translate('quality-damage-item'),
    QUALITY_HIGHLIGHT: translate('quality-highlight-item'),
    PAINT_THICKNESS_LABEL: translate('quality-paint-thickness-label'),
    PAINT_THICKNESS_HEADER: translate('quality-paint-thickness-header'),
    PAINT_THICKNESS_DESCRIPTION: translate(
      'quality-paint-thickness-description',
    ),
    PAINT_THICKNESS_DISCLAIMER: translate('quality-paint-thickness-disclaimer'),
    WAS_PAINT_THICKNESS_CHECKED: translate(
      'quality-paint-thickness-would-you-add-measures',
    ),
    PAINT_THICKNESS_DESCRIPTION_INFO: translate(
      'quality-paint-thickness-description-info',
    ),
    PAINT_THICKNESS_QUESTION_LEFT: translate(
      'quality-paint-thickness-question-left',
    ),
    PAINT_THICKNESS_QUESTION_RIGHT: translate(
      'quality-paint-thickness-question-right',
    ),
    PAINT_THICKNESS_QUESTION_REAR: translate(
      'quality-paint-thickness-question-rear',
    ),
    PAINT_THICKNESS_QUESTION_ROOF: translate(
      'quality-paint-thickness-question-roof',
    ),
    PAINT_THICKNESS_QUESTION_FRONT: translate(
      'quality-paint-thickness-question-front',
    ),
    PAINT_THICKNESS_RANGE_VALIDATION: translate(
      'quality-paint-thickness-range-validation',
    ),
    DAMAGES_DESCRIPTION_INFO: translate('quality-damages-description-info'),
    DAMAGES_HEADER: translate('quality-damages-header'),
    DAMAGES_DESCRIPTION: translate('quality-damages-description'),
    DAMAGES_TYPE: translate('quality-damages-type'),
    DAMAGES_SEVERITY: translate('quality-damages-severity'),
    DAMAGES_ACCIDENT_REASON: translate('quality-damages-accident-reason'),
    DAMAGES_ADD_BUTTON: translate('quality-damages-addBtn'),
    HIGHLIGHTS_HEADER: translate('quality-highlights-header'),
    HIGHLIGHTS_DESCRIPTION: translate('quality-highlights-description'),
    HIGHLIGHTS_ADD_BUTTON: translate('quality-highlights-addBtn'),

    OTHER_EQUIPMENT_ACCORDING_VIN_DATA: translate(
      'other-equipment-according-vin',
    ),
    SEATS_NUMBER: translate('seats-number'),
    EXTERIOR_COLOR: translate('exterior-color'),
    METALLIC: translate('metallic'),
    ORIGINAL_COLOR: translate('original-color'),
    TUNING: translate('tuning'),
    KEYS_AMOUNT: translate('keys-amount'),
    MATERIAL: translate('material'),
    ORIGINAL_UPHOLSTERY: translate('original-upholstery'),
    TUNING_NOTE: translate('tuning-note'),
    LPG: translate('lpg'),
    LPG_NOTE: translate('lpg-note'),
    CHARGING_CABLES: translate('charging-cables'),
    PLEASE_SELECT: translate('please-select'),
    LOCATION_SETTINGS: translate('location-settings'),
    CHECK_DIGIT: translate('check-digit'),
    ORIGINAL: translate('original'),
    NOT_ORIGINAL: translate('not-original'),
    INVALID_STOCK_NUMBER: translate('invalid-stocknumber'),
    FAILED_LOADING_TRANSLATIONS: translate('failed-loading-translations'),
    FAILED_LOADING_USER: translate('failed-loading-user'),
    USER_NOT_ALLOWED: translate('user-not-allowed'),
    ADD_LOCATION: translate('add-location'),
    LOCATIONS_PAGE_TITLE: translate('locations-page-title'),
    LOCATIONS_RESULT_NOT_FOUND: translate('location-not-found'),
    LOCATIONS_INPUT_LESS_THAN_THREE: translate(
      'location-input-less-than-three',
    ),
    LOCATIONS_ADDRESS_ERROR: translate('location-address-error'),
    SOME_FILES_MAY_NOT_BE_INCLUDED: translate('some-files-may-not-be-included'),
    ONLY_PDF_FILE_SUPPORTED: translate('only-pdf-supported'),
    ONLY_ONE_FILE_IS_ALLOWED_HERE: translate('only-one-file-is-allowed-here'),
    SOME_FILES_COULD_NOT_BE_PROCESSED: translate(
      'some-files-could-not-be-processed',
    ),

    // Prevent Leave Modal
    UNSAVED_CHANGES: translate('unsaved-changes'),
    WOULD_YOU_LIKE_SAVE_CHANGES: translate('would-you-like-save-changes'),
    EDITING_IS_NOT_POSSIBLE: translate('editing-is-not-possible'),
    UNAUTHORIZED_PAGE_MESSAGE: translate('unauthorized-page-message'),

    // Mobile Page
    MOBILE_PAGE_TITLE: translate('mobile-page-title'),
    MOBILE_PAGE_DOWNLOAD: translate('mobile-page-download'),
    MOBILE_PAGE_DESCRIPTION: translate('mobile-page-description'),

    // Duplicate VIN Warning Modal
    EVALUATION_ALREADY_EXISTS: translate('evaluation-already-exists'),
    PI_ALREADY_EXISTS: translate('pi-already-exists'),
    CONTINUE_EVALUATION: translate('continue-evaluation'),
    COMPLETE_NOW: translate('complete-now'),
    CREATE_NEW_EVALUATION: translate('create-new-evaluation'),
    VIEW_PRICE_INDICATOR: translate('view-price-indicator'),
    WOULD_YOU_LIKE_CONTINUE_EVALUATION: translate(
      'would-you-like-continue-evaluation',
    ),
    EVALUATION_UPDATED: translate('evaluation-updated'),

    // Block rerun Warning Modal
    EVALUATION_CANNOT_BE_CREATED: translate('evaluation-cannot-be-created'),
    EVALUATION_CANNOT_BE_SUBMITTED: translate('evaluation-cannot-be-submitted'),
    EXISTING_AUCTION_FOR_THIS_VIN: translate('existing-auction-for-this-vin'),
    VIEW_EXISTING_AUCTION: translate('view-existing-auction'),
    VIEW_EXISTING_AUCTION_WARNING: translate('view-existing-auction-warning'),
    VIEW_EXISTING_AUCTION_WARNING_TEXT: translate(
      'view-existing-auction-warning-text',
    ),
    NO_RERUN_VIEW_EXISTING_AUCTION: translate('no-rerun-view-existing-auction'),
    VIEW_AUCTION: translate('view-auction'),
    CLOSE: translate('close'),
    CONTINUE: translate('continue'),
    CHANGE_VIN_ERROR_RERUN_LIMIT_EXHAUSTED: translate(
      'change-vin-error-rerun-limit-exhausted',
    ),
    RERUN_LIMIT_EXHAUSTED: translate('rerun-limit-exhausted'),
    EXISTING_AUCTION_TITLE: translate('existing-auction-title'),

    // Dynamic Auctions
    DYNAMIC_AUCTION: translate('dynamic-auction'),
    DYNAMIC_AUCTION_TOOLTIP_TEXT: translate('dynamic-auction-tooltip-text'),
    DYNAMIC_AUCTION_AUCTION_TYPE: translate('dynamic-auction-auction-type'),
    DYNAMIC_AUCTION_MODAL_NOTE: translate('dynamic-auction-modal-note'),

    // Edit PI2
    CONVERT_FULL_EVA: translate('convert-full-evaluation'),
    WANT_CONVERT_FULL_EVA: translate('want-convert-full-evaluation'),

    // Project Beam
    THIS_IS_YOUR_LOCATION_ADDRESS: translate('this-is-your-location-address'),
    EDIT_ADDRESS: translate('edit-address'),
    CHOOSE_AUCTION: translate('choose-auction'),
    AUTO1_FULL_SERVICE: translate('auto1-full-service'),
    AUTO1_DIRECT: translate('auto1-direct'),
    CONTRACT_PARTNER: translate('contract-partner'),
    CONTRACT_PARTNER_FULL_SERVICE: translate('contract-partner-full-service'),
    CONTRACT_PARTNER_DIRECT: translate('contract-partner-direct'),
    POTENTIAL_BUYER: translate('potential-buyer'),
    POTENTIAL_BUYER_FULL_SERVICE: translate('potential-buyer-full-service'),
    POTENTIAL_BUYER_DIRECT: translate('potential-buyer-direct'),
    CAR_HANDOVER: translate('car-handover'),
    CAR_HANDOVER_FULL_SERVICE: translate('car-handover-full-service'),
    CAR_HANDOVER_DIRECT: translate('car-handover-direct'),
    DOCUMENT_HANDOVER: translate('document-handover'),
    DOCUMENT_HANDOVER_FULL_SERVICE: translate('document-handover-full-service'),
    DOCUMENT_HANDOVER_DIRECT: translate('document-handover-direct'),
    PAYMENT: translate('payment'),
    PAYMENT_FULL_SERVICE: translate('payment-full-service'),
    PAYMENT_DIRECT: translate('payment-direct'),
    CLAIMS_HANDLING: translate('claims_handling'),
    CLAIMS_HANDLING_FULL_SERVICE: translate('claims_handling-full-service'),
    CLAIMS_HANDLING_DIRECT: translate('claims_handling-direct'),
    TOTAL_BUYER_FEE: translate('total-buyer-fee'),
    TOTAL_BUYER_FEE_FULL_SERVICE: translate('total-buyer-fee-full-service'),
    TOTAL_BUYER_FEE_DIRECT: translate('total-buyer-fee-direct'),
    SELECT_FULL_SERVICE: translate('select-full-service'),
    SELECT_DIRECT: translate('select-direct'),
    DIRECT_AUCTION_MODAL_TITLE: translate('direct-auction-modal-title'),
    DIRECT_AUCTION_MODAL_EXPECTED_PRICE: translate(
      'direct-auction-modal-expected-price',
    ),
    DIRECT_AUCTION_MODAL_COMPANY_NAME: translate(
      'direct-auction-modal-company-name',
    ),
    DIRECT_AUCTION_MODAL_NAME: translate('direct-auction-modal-name'),
    DIRECT_AUCTION_MODAL_LAST_NAME: translate('direct-auction-modal-last-name'),
    DIRECT_AUCTION_MODAL_ADDRESS: translate('direct-auction-modal-address'),
    DIRECT_AUCTION_MODAL_PHONE_NUMBER: translate(
      'direct-auction-modal-phone-number',
    ),
    DIRECT_AUCTION_MODAL_EMAIL: translate('direct-auction-modal-email'),
    DIRECT_AUCTION_MODAL_DESCRIPTION: translate(
      'direct-auction-modal-description',
    ),

    // Guaranteed Price
    GP_ACCEPTED: translate('gp-accepted'),
    GP_EXPIRED: translate('gp-expired'),
    GP_REJECTED: translate('gp-rejected'),
    GP_PENDING_ACTION: translate('gp-pending-action'),

    // REMA 2.0
    SELLING_CHANNEL: translate('selling-channel'),
    SELL_DIRECTLY_TO_AUTO1: translate('sell-directly-to-auto1'),
    SELL_BY_AUCTION: translate('sell-by-auction'),
    INSTANT_OFFER: translate('instant-offer'),
    INSTANT_OFFER_DESCRIPTION: translate('instant-offer-description'),
    REMARKETING_24H_AUCTION: translate('remarketing-24h-auction'),
    REMARKETING_24H_AUCTION_DESCRIPTION: translate(
      'remarketing-24h-auction-description',
    ),
    BATCH_AUCTION: translate('batch-auction'),
    BATCH_AUCTION_DESCRIPTION: translate('batch-auction-description'),
    SUBMIT_AUCTION_BUTTON: translate('submit-auction-button'),
    // submit auction popup
    SET_EXPECTATION_PRICE_FOR_24H_AUCTION: translate(
      'set-expectation-price-24h-auction',
    ),
    SET_EXPECTATION_PRICE_FOR_BATCH_AUCTION: translate(
      'set-expectation-price-batch-auction',
    ),
    EXPECTATION_PRICE: translate('expectation-price'),
    CONFIRM_SUBMIT_AUCTION: translate('confirm'),
    BATCH_AUCTION_SIDE_NOTE: translate('batch-auction-side-note'),
    REMARKETING_24H_AUCTION_SIDE_NOTE: translate(
      'remarketing-24h-auction-side-note',
    ),
    START_PRICE_LABEL: translate('start-price-label'),
    START_PRICE_HELPER_MESSAGE: translate('start-price-helper-message'),
    START_PRICE_CALCULATING_HELPER_MESSAGE: translate(
      'start-price-calculating-helper-message',
    ),
    START_PRICE_ERROR_MESSAGE_HIGHER_THAN_MAX: translate(
      'start-price-error-message-higher-than-max',
    ),
    START_PRICE_ERROR_MESSAGE_LOWER_THAN_MIN: translate(
      'start-price-error-message-lower-than-min',
    ),
    START_PRICE_ERROR_MESSAGE_SERVER_FAIL: translate(
      'start-price-error-message-server-fail',
    ),
    RETRY: translate('start-price-retry-button'),

    // DAT timeout
    EMPTY_VIN_MODAL_TITLE: translate('no-vin-modal-title'),
    EMPTY_VIN_MODAL_TEXT: translate('no-vin-modal-text'),
    DAT_TIMEOUT_MODAL_TITLE: translate('dat-timeout-modal-title'),
    DAT_TIMEOUT_MODAL_TEXT: translate('dat-timeout-modal-text'),

    // Service Docs
    ...ServiceDocuments(translate),
    ...sideBarTranslations(translate),
    ...carQuality(translate),
    ...CompletedPage(translate),
    ...Inspectionslist(translate),
    ...upholsteryOptions(translate),
    ...Validation(translate),
    ...LocationsPage(translate),
    ...AutoPrice(translate),
    ...BranchesPagination(translate),
  });
};

type Translations = ReturnType<typeof TranslationEnum>;

export { TranslationEnum };
export type { Translate, Translations };
