import omit from 'lodash/omit';
import type {
  CarQualityInitialDataQuery,
  MetaPaintThickness,
  VehicleConditionPaintThickness,
  PainThicknessOption,
  VehicleConditionPaintThicknessArea,
} from 'gql/graphql';
import { filterNull } from 'shared/utils/filterNull';
import type { Form, PaintThicknessV1Form, PaintThicknessV2Form } from './types';

function mapPaintThicknessV1ToForm(
  paintThickness?: MetaPaintThickness | null,
): PaintThicknessV1Form {
  if (paintThickness === null || paintThickness === undefined) {
    return {
      hood: null,
      left: null,
      back: null,
      right: null,
      roof: null,
      version: 1,
    };
  }

  return {
    ...Object.entries(paintThickness)
      .filter(([key]) => key !== '__typename')
      .reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: value ?? null,
        };
      }, {} as Record<keyof Omit<MetaPaintThickness, '__typename'>, boolean | null>),
    version: 1,
  };
}

function mapPaintThicknessV2ToForm(
  paintThickness: Array<VehicleConditionPaintThickness | null>,
  options: Array<PainThicknessOption | null>,
  isPaintThicknessChecked: boolean | null,
): PaintThicknessV2Form {
  return {
    version: 2,
    areas: options.filter(filterNull),
    isPaintThicknessChecked: isPaintThicknessChecked ?? null,
    measurements: options
      .filter(filterNull)
      .reduce<PaintThicknessV2Form['measurements']>((acc, { area }) => {
        const paintThicknessFound = paintThickness
          .filter(filterNull)
          .find(
            ({ area: vehicleConditionPaintThicknessArea }) =>
              vehicleConditionPaintThicknessArea === area,
          );

        acc[area] = paintThicknessFound
          ? {
              value: paintThicknessFound.value ?? null,
              isNotMeasurable: paintThicknessFound.isNotMeasurable ?? null,
            }
          : {
              value: null,
              isNotMeasurable: false,
            };

        return acc;
      }, {} as PaintThicknessV2Form['measurements']),
  };
}

function mapHighlightsToForm(
  highlights: NonNullable<
    NonNullable<
      NonNullable<CarQualityInitialDataQuery['inspection']>['meta']
    >['highlights']
  >,
) {
  return highlights.map((highlight) => ({
    area: highlight.area ?? null,
    part: highlight.part ?? null,
    files: highlight.files ?? [],
  }));
}

function mapDamagesToForm(
  damages: NonNullable<
    NonNullable<
      NonNullable<CarQualityInitialDataQuery['inspection']>['vehicle']
    >['damages']
  >,
) {
  return damages.map((damage) => ({
    area: damage.area ?? null,
    part: damage.part ?? null,
    type: damage.type ?? null,
    files: damage.files ?? [],
    isAccidentReason: damage.isAccidentReason ?? null,
  }));
}

const mapDataToFormValues = (
  inspection: CarQualityInitialDataQuery['inspection'],
): Form => {
  const damages = mapDamagesToForm(inspection?.vehicle?.damages || []);
  const highlights = mapHighlightsToForm(inspection?.meta?.highlights || []);
  const paintThicknessVersion = inspection?.meta?.evaluation?.paintThickness
    ? 1
    : 2;
  const paintThickness =
    paintThicknessVersion === 1
      ? mapPaintThicknessV1ToForm(inspection?.meta?.evaluation?.paintThickness)
      : mapPaintThicknessV2ToForm(
          inspection?.vehicle?.condition?.paintThicknesses ?? [],
          inspection?.fields?.vehicle?.paintThickness ?? [],
          inspection?.flags?.isPaintThicknessChecked ?? null,
        );

  return {
    paintThickness,
    highlights,
    damages: damages.map((damage) => ({
      ...damage,
      isAccidentReason: damage.isAccidentReason ?? false,
    })),
  };
};

function mapPaintThicknessToMutation(
  paintThickness: PaintThicknessV1Form | PaintThicknessV2Form,
) {
  if (paintThickness.version === 1) {
    return { ...omit(paintThickness, '__typename', 'version') };
  }

  return paintThickness.isPaintThicknessChecked
    ? Object.entries(paintThickness.measurements).map(
        ([area, { value, isNotMeasurable }]) => ({
          area: area as VehicleConditionPaintThicknessArea,
          value: isNotMeasurable ? null : value,
          isNotMeasurable: isNotMeasurable ? true : null,
        }),
      )
    : [];
}

const mapDataToMutation = (values: Form) => {
  return {
    ...values,
    isPaintThicknessChecked:
      'isPaintThicknessChecked' in values.paintThickness
        ? values.paintThickness.isPaintThicknessChecked
        : null,
    paintThickness: mapPaintThicknessToMutation(values.paintThickness),
    damages: values.damages.map((damage) => ({
      ...omit(damage, '__typename'),
      files: damage.files.map((image) =>
        image instanceof File ? image : omit(image, '__typename'),
      ),
    })),
    highlights: values.highlights.map((highlight) => ({
      ...omit(highlight, '__typename'),
      files: highlight.files.map((image) =>
        image instanceof File ? image : omit(image, '__typename'),
      ),
    })),
  };
};

export { mapDataToFormValues, mapDataToMutation };
