import * as React from 'react';
import { RoutesEnum } from 'shared/utils/route';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useInspection } from 'shared/components/InspectionContext';
import {
  getSectionProgress,
  getPageProgress,
  getSectionErrorsYup,
} from 'shared/utils/calculateProgress';
import { CarQualitySchema } from '../car-quality.schema';

const sections = [
  {
    sectionName: RoutesEnum.CAR_QUALITY_THICKNESS,
    prefix: 'paintThickness',
    requiredFieldsNb: 5,
    isOptional: false,
  },
  {
    sectionName: RoutesEnum.CAR_QUALITY_DAMAGES,
    prefix: 'damages',
    requiredFieldsNb: 4,
    isOptional: true,
  },
  {
    sectionName: RoutesEnum.CAR_QUALITY_HIGHLIGHTS,
    prefix: 'highlights',
    requiredFieldsNb: 3,
    isOptional: true,
  },
] as const;

const useProgress = () => {
  const { setProgress } = useInspection();
  const { translations } = useTranslation();

  const getCarQualityProgress = React.useCallback(
    (formValues) => {
      const progressArray: number[] = [];
      const paintThicknessVersion = formValues.paintThickness.version;
      const paintThicknessAreas = formValues.paintThickness.areas;

      const validationSchema = CarQualitySchema(
        translations,
        false,
        paintThicknessAreas,
        paintThicknessVersion,
      );

      validationSchema
        .validate(formValues, { abortEarly: false })
        .then(() => {
          const sectionsProgress = sections.reduce((acc, { sectionName }) => {
            acc[sectionName] = 100;

            return acc;
          }, {} as Record<typeof sections[number]['sectionName'], number>);
          setProgress({
            [RoutesEnum.CAR_QUALITY]: 100,
            ...sectionsProgress,
          });
        })
        .catch((e) => {
          const errors = e.inner;
          const sectionsProgress = sections.reduce(
            (acc, { sectionName, requiredFieldsNb, prefix, isOptional }) => {
              let totalFieldsNb;
              const sectionErrors = getSectionErrorsYup(errors, prefix);
              if (sectionName === RoutesEnum.CAR_QUALITY_THICKNESS) {
                if (paintThicknessVersion === 1) {
                  totalFieldsNb = requiredFieldsNb;
                } else {
                  totalFieldsNb = formValues.paintThickness
                    .isPaintThicknessChecked
                    ? paintThicknessAreas.length
                    : 1;
                }
              } else {
                totalFieldsNb =
                  (formValues?.[prefix]?.length ?? 0) * requiredFieldsNb;
              }
              const progress = getSectionProgress({
                errors: sectionErrors,
                totalFieldsNb,
                isOptional,
              });

              if (
                sectionName === RoutesEnum.CAR_QUALITY_THICKNESS ||
                formValues?.[prefix]?.length > 0
              ) {
                progressArray.push(progress);
              }
              acc[sectionName] = progress;

              return acc;
            },
            {} as Record<typeof sections[number]['sectionName'], number>,
          );

          setProgress({
            [RoutesEnum.CAR_QUALITY]: getPageProgress(progressArray),
            ...sectionsProgress,
          });
        });
    },
    [translations, setProgress],
  );

  return getCarQualityProgress;
};

export { useProgress };
