import type { ErrorResponse } from '@apollo/client/link/error';

type GraphQLErrors = ErrorResponse['graphQLErrors'];
type NetworkError = ErrorResponse['networkError'];
type Operation = ErrorResponse['operation'];
type Response = ErrorResponse['response'];

const LIMIT_ERROR_MESSAGE = 1000;
const TRACE_ID_HEADER = 'x-b3-traceid';

export class GatewayError extends Error {
  public status: number;
  public traceId: string | null;
  public variables: string;
  public data: string;
  public operationName: string;

  constructor(
    graphQLErrors: GraphQLErrors,
    networkError: NetworkError,
    operation: Operation,
    response: Response,
  ) {
    super(GatewayError.constructErrorMessage(graphQLErrors, networkError));

    this.status = operation.getContext().response?.status;
    this.traceId = operation
      .getContext()
      .response?.headers?.get(TRACE_ID_HEADER);
    this.variables = JSON.stringify(operation.variables);
    this.data = JSON.stringify(response?.data);
    this.operationName = operation.operationName;
  }

  private static constructErrorMessage(
    graphQLErrors: GraphQLErrors,
    networkError: NetworkError,
  ): string {
    let errorMessage = '[GatewayError] ';

    if (graphQLErrors && graphQLErrors.length > 0) {
      errorMessage += graphQLErrors.map((error) => error?.message).join('; ');
    } else if (networkError) {
      errorMessage += networkError.message;
    } else {
      errorMessage += 'Unknown error';
    }

    // Limit to 1000 characters to ensure compatibility with Kibana.
    return errorMessage.length > LIMIT_ERROR_MESSAGE - 3
      ? `${errorMessage.substring(0, LIMIT_ERROR_MESSAGE - 3)}...`
      : errorMessage;
  }
}
