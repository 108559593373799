import React from 'react';

import type { VehicleImagePart } from 'gql/graphql';
import { useTranslation } from 'shared/hooks/useTranslation';
import { FormMediaUploader } from 'shared/components/FormMediaUploader';
import { ImagePreview } from 'shared/components/ImagePreview';
import { VideoPreview } from 'shared/components/VideoPreview';
import { Modal } from 'shared/components/Modal';
import { VideoThumb } from 'shared/components/VideoThumb';
import { useInspection } from 'shared/components/InspectionContext';
import Loader from 'shared/components/Loader';
import { useCarPictureItem } from './useCarPictureItem';
import styles from './index.module.scss';

type Props = {
  placeholder?: React.ReactNode;
  qaIdPrefix?: string;
  label: string;
  area: string;
  className?: string;
  mediaUrl: string;
  mediaData: { stockNumber: string; part: VehicleImagePart };
  errorClassName?: string;
  sourceType: 'video' | 'image';
};

const CarPictureItem: React.FC<Props> = ({
  className = '',
  errorClassName = '',
  sourceType,
  label,
  qaIdPrefix = 'car-pictures',
  placeholder = '',
  area,
  mediaUrl,
  mediaData,
}) => {
  const {
    ref,
    source,
    isOpen,
    isVideo,
    buttons,
    setIsOpen,
    isUploading,
    isOpenConfirmation,
    handleCancelConfirmation,
    handleDeleteConfirmation,
  } = useCarPictureItem({ sourceType, name: area, mediaUrl, mediaData });
  const { inspectionId, stockNumber } = useInspection();
  const { translations } = useTranslation();
  const [isProcessing, setIsProcessing] = React.useState(false);

  return (
    <>
      <Modal
        isOpen={isOpenConfirmation}
        onOk={handleDeleteConfirmation}
        title={translations.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        okLabel={translations.DELETE}
        cancelLabel={translations.CANCEL}
        onCancel={handleCancelConfirmation}
        qaIdPrefix={`${qaIdPrefix}-confirmation-modal`}
      />
      {!isVideo && source && source.length > 0 && (
        <ImagePreview
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          buttons={buttons}
          imageSrc={source}
        />
      )}
      {isVideo && source && source.length > 0 && (
        <VideoPreview
          isOpen={isOpen}
          handleClose={() => setIsOpen(false)}
          buttons={buttons}
          videoFile={source}
        />
      )}
      <div
        ref={ref}
        className={styles.col}
        data-qa-id={`${qaIdPrefix}-car-picture-item`}
      >
        <FormMediaUploader
          name={area}
          title={label}
          qaIdPrefix={`${qaIdPrefix}-car-picture-item`}
          errorClassName={errorClassName}
          placeHolderAdditionalClassName={className}
          hasValueClassName={styles.hasValue}
          showLoader={isUploading || isProcessing}
          placeHolder={placeholder}
          sourceType={sourceType}
          onClickIcon={() => {
            window.tracking.eva[
              `${isVideo ? 'previewVideo' : 'previewPhoto'}CarPhotos`
            ]?.({
              area,
              inspectionId,
              stockNumber,
            });
            setIsOpen(true);
          }}
          VideoComponent={isVideo && source?.length && VideoThumb}
          customLoader={<Loader size={36} color="white" />}
          loaderOverlayAdditionalClassName={styles.loaderOverlay}
          onIsProcessingChanged={(isProcessing: boolean) => {
            setIsProcessing(isProcessing);
          }}
        />
      </div>
    </>
  );
};

export { CarPictureItem };
