import * as React from 'react';

import { useTranslation } from 'shared/hooks/useTranslation';
import { useCarInfo } from 'shared/components/CarInfoContext';
import { useProgressCalculation } from './hooks/useProgressCalculation';
import { ListItem } from './components/ListItem';
import styles from './index.module.scss';
import { sideBarRoutes } from './sideBarRoutes';
import { useInspection } from '../InspectionContext';

type Props = {
  qaId?: string;
};

const SideBar: React.FC<Props> = ({ qaId = 'sidebar' }) => {
  const { translations } = useTranslation();

  const { notCompletedSteps, isPdfInspection, inspectionId } = useInspection();
  const isNotCompleted = (requiredMutations: string[] = []) => {
    if (notCompletedSteps?.length > 0) {
      return notCompletedSteps.some((step) => requiredMutations.includes(step));
    }
    return false;
  };

  useProgressCalculation(inspectionId);

  const { isElectricCar } = useCarInfo();

  return (
    <aside className={styles.sideBar} data-qa-id={`${qaId}-container`}>
      <h3 className={styles.sideBarTitle}>{translations.CAR_INSPECTION}</h3>
      <ul className={styles.sideBarList} data-qa-id={`${qaId}-list`}>
        {sideBarRoutes(
          inspectionId.toString(),
          translations,
          isPdfInspection,
          isElectricCar,
        ).map((item) => (
          <ListItem
            key={item.id}
            notCompleted={isNotCompleted(item.requiredMutations)}
            {...item}
          />
        ))}
      </ul>
    </aside>
  );
};

export { SideBar };
