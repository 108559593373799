import * as yup from 'yup';

import { requiredUrl } from 'shared/utils/yupValidationHelpers';
import type { Translations } from 'shared/utils/translations';
import { VEHICLE_MEDIA_AREAS } from './constants';

const CarPictureSchema = (
  translations: Translations,
  optional: boolean,
  isPdfInspection: boolean,
) => {
  if (isPdfInspection) {
    return yup.object({
      [VEHICLE_MEDIA_AREAS.FRONT_LEFT]: requiredUrl(
        optional,
        translations.CAR_IMAGES_PDF_VALIDATION_DISCLAIMER,
      ),
    });
  }

  return yup.object({
    [VEHICLE_MEDIA_AREAS.SPEEDOMETER]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.REAR_INTERIOR]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.CONSOLE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.FRONT_INTERIOR]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.FRONT]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.ENGINE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.FRONT_LEFT_SIDE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.FRONT_LEFT]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.REAR_LEFT_SIDE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.REAR]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.REAR_RIGHT_SIDE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.FRONT_RIGHT_SIDE]: requiredUrl(
      optional,
      translations.PLEASE_UPLOAD_ALL_PICTURES,
    ),
    [VEHICLE_MEDIA_AREAS.TRUNK]: yup.string().url().nullable(),
    [VEHICLE_MEDIA_AREAS.NAVIGATION_SYSTEM]: yup.string().url().nullable(),
    [VEHICLE_MEDIA_AREAS.GENERAL]: yup
      .array()
      .of(
        yup.mixed().test('Url or file', (value) => {
          if (value instanceof File) {
            return true;
          }

          try {
            yup
              .object({
                absoluteUrl: yup.string(),
              })
              .validateSync(value);
          } catch (error) {
            return false;
          }

          return true;
        }),
      )
      .nullable(),
  });
};

const carPictureInsideItems = (translations: Translations) => {
  return [
    {
      label: translations.SPEEDOMETER_WITH_STARTED_ENGINE,
      area: VEHICLE_MEDIA_AREAS.SPEEDOMETER,
    },
    {
      label: translations.INTERIOR_BACK,
      area: VEHICLE_MEDIA_AREAS.REAR_INTERIOR,
    },
    {
      label: translations.CENTER_CONSOLE,
      area: VEHICLE_MEDIA_AREAS.CONSOLE,
    },
    {
      label: translations.INTERIOR_FRONT,
      area: VEHICLE_MEDIA_AREAS.FRONT_INTERIOR,
    },
  ];
};

const carPictureOutsideItems = (translations: Translations) => {
  return [
    {
      label: translations.FRONT,
      area: VEHICLE_MEDIA_AREAS.FRONT,
    },
    {
      label: translations.ENGINE_COMPARTMENT,
      area: VEHICLE_MEDIA_AREAS.ENGINE,
    },
    {
      label: translations.LEFT_FRONT,
      area: VEHICLE_MEDIA_AREAS.FRONT_LEFT,
    },
    {
      label: translations.LEFT_SIDE_FRONT,
      area: VEHICLE_MEDIA_AREAS.FRONT_LEFT_SIDE,
    },
    {
      label: translations.LEFT_SIDE_BACK,
      area: VEHICLE_MEDIA_AREAS.REAR_LEFT_SIDE,
    },
    {
      label: translations.BACK,
      area: VEHICLE_MEDIA_AREAS.REAR,
    },
    {
      label: translations.RIGHT_SIDE_BACK,
      area: VEHICLE_MEDIA_AREAS.REAR_RIGHT_SIDE,
    },
    {
      label: translations.RIGHT_SIDE_FRONT,
      area: VEHICLE_MEDIA_AREAS.FRONT_RIGHT_SIDE,
    },
  ];
};

const carPictureOptionalItems = (translations: Translations) => {
  return [
    {
      area: VEHICLE_MEDIA_AREAS.NAVIGATION_SYSTEM,
      label: translations.NAVIGATION,
    },
    {
      label: translations.TRUNK,
      area: VEHICLE_MEDIA_AREAS.TRUNK,
    },
    {
      label: translations.ENGINE_VIDEO,
      sourceType: 'video',
      area: VEHICLE_MEDIA_AREAS.ENGINE,
    },
  ] as const;
};

export {
  CarPictureSchema,
  carPictureInsideItems,
  carPictureOutsideItems,
  carPictureOptionalItems,
  VEHICLE_MEDIA_AREAS,
};
