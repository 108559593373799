import * as React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import kebabCase from 'lodash/kebabCase';
import { apm } from '@elastic/apm-rum';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useHistory } from 'react-router-dom';

import { appRoutes } from 'appRoutes';
import { useInspection } from 'shared/components/InspectionContext';
import { RoutesEnum } from 'shared/utils/route';
import { scrollToError } from 'shared/utils/scrollToError';
import { useSubmitTracking } from 'shared/hooks/useSubmitTracking';
import { useMultipleMediaUploader } from 'shared/utils/useMultipleMediaUploader';
import { fileFilter } from 'shared/utils/fileFilter';
import {
  useCarPicturesVehicleQuery,
  useReplaceMainCarImagesMutation,
  useReplaceAdditionalCarImagesMutation,
} from 'gql/graphql';
import type { VehicleImageArea } from 'gql/graphql';
import { getMimeType, mapDataToFormValues } from '../helpers';
import {
  carPictureInsideItems,
  carPictureOutsideItems,
  carPictureOptionalItems,
} from '../carPicturesData';
import { CarPictureSchema } from '../carPicturesData';
import { VEHICLE_MEDIA_AREAS } from '../constants';
import { useProgress } from './useProgress';
import type { Form, FileData } from '../types';

const useCarPictures = () => {
  const history = useHistory();

  const {
    removeStepFromNotCompleted,
    stockNumber,
    isPdfInspection,
    inspectionId,
  } = useInspection();
  const { translations } = useTranslation();
  const submitContext = React.useRef<'save' | 'submit' | null>(null);
  const methods = useForm({
    context: submitContext,
    resolver: async (data, context, options) => {
      const isOptional = context?.current === 'save';

      return yupResolver(
        CarPictureSchema(translations, isOptional, isPdfInspection),
      )(data, context, options);
    },
  });
  const { submitTracking } = useSubmitTracking('carPhotos');
  const { uploadMedia: uploadMultipleMedia } = useMultipleMediaUploader(
    `/v1.1/car/${stockNumber}/image`,
  );

  const { loading } = useCarPicturesVehicleQuery({
    variables: {
      inspectionId,
    },
    onCompleted: (data) => {
      const formData = mapDataToFormValues(data?.inspection);
      methods.reset(formData);
    },
  });

  const [
    submitPictures,
    { loading: loadingSubmit, error: mutationMainImagesError },
  ] = useReplaceMainCarImagesMutation();
  const [
    submitAdditionalImages,
    { loading: loadingAdditionalImages, error: mutationAdditionalImagesError },
  ] = useReplaceAdditionalCarImagesMutation();
  const [uploadError, setUploadError] = React.useState('');
  const serverError =
    mutationMainImagesError?.graphQLErrors?.[0]?.message ??
    mutationAdditionalImagesError?.graphQLErrors?.[0]?.message ??
    uploadError;

  const insideSectionItems = React.useMemo(
    () => carPictureInsideItems(translations),
    [translations],
  );
  const outsideSectionItems = React.useMemo(
    () => carPictureOutsideItems(translations),
    [translations],
  );
  const optionalSectionItems = React.useMemo(
    () => carPictureOptionalItems(translations),
    [translations],
  );

  const setProgress = useProgress();

  const submitErrorHandler = (errors: object) => {
    const [firstErrorField] = Object.keys(errors);
    scrollToError(`[data-qa-id*="${kebabCase(firstErrorField)}"]`);
    setProgress(methods.getValues());
  };
  const submitHandler = async (formValues: Form) => {
    const isOptional = submitContext?.current === 'save';
    try {
      const carGeneralImages = fileFilter(
        formValues[VEHICLE_MEDIA_AREAS.GENERAL] ?? [],
      );
      const carGeneralImagesToSave = await uploadMultipleMedia(
        carGeneralImages.files.map((file) => ({
          stockNumber,
          file,
          part: 'vehicle__general',
          source: 11,
        })),
      ).then((results) => [
        ...carGeneralImages.previouslyUploadedImages,
        ...results.map(
          ({
            data: {
              wkda: { url },
            },
          }) => ({
            absoluteUrl: url,
            area: VEHICLE_MEDIA_AREAS.GENERAL,
            mimeType: getMimeType(url),
          }),
        ),
      ]);

      const reduce = (acc: Array<FileData>, key: keyof Form) => {
        if (!formValues[key] || key === VEHICLE_MEDIA_AREAS.GENERAL) return acc;
        const url = formValues[key] as string;
        return [
          ...acc,
          {
            absoluteUrl: url,
            area: key.replace('__VIDEO', '') as VehicleImageArea,
            mimeType: getMimeType(url),
          },
        ];
      };

      const images = (Object.keys(formValues) as Array<keyof Form>).reduce<
        Array<FileData>
      >(reduce, []);
      await Promise.all([
        submitPictures({
          variables: {
            inspectionId,
            images,
            incomplete: isOptional,
          },
        }),
        submitAdditionalImages({
          variables: {
            inspectionId,
            images: carGeneralImagesToSave,
            incomplete: isOptional,
          },
        }),
      ]);

      methods.reset(formValues);
      removeStepFromNotCompleted(RoutesEnum.CAR_PICTURES);
      if (!isOptional) {
        submitTracking();
        history.push(appRoutes.carQuality(`${inspectionId}`, true));
      }
    } catch (e) {
      setUploadError((e as Error).message);
      apm.captureError(e as Error);
    }
  };

  return {
    methods,
    loading,
    stockNumber,
    submitErrorHandler,
    submitHandler,
    submitContext,
    loadingSubmit,
    loadingAdditionalImages,
    insideSectionItems,
    outsideSectionItems,
    optionalSectionItems,
    serverError,
  };
};

export { useCarPictures };
