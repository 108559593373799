import * as React from 'react';

import { useServiceDocumentsInitialDataQuery } from 'gql/graphql';
import { useProgress } from 'pages/ServicesDocuments/hooks/useProgress';
import { formatDataToFormValues } from 'pages/ServicesDocuments/helpers';

function useServicesDocumentsProgress(inspectionId: number) {
  const { data } = useServiceDocumentsInitialDataQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setServicesDocumentsProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setServicesDocumentsProgress(formatDataToFormValues(data?.inspection));
  }, [data, setServicesDocumentsProgress]);

  React.useEffect(() => {
    setServicesDocumentsProgress(formatDataToFormValues(data?.inspection));
  }, [data, setServicesDocumentsProgress]);

  return updateProgress;
}

export { useServicesDocumentsProgress };
