import * as React from 'react';
import { useProgress } from 'pages/CarPictures/hooks/useProgress';
import { useCarPicturesVehicleQuery } from 'gql/graphql';
import { mapDataToFormValues } from 'pages/CarPictures/helpers';

function useCarPicturesProgress(inspectionId: number) {
  const { data } = useCarPicturesVehicleQuery({
    variables: {
      inspectionId,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !inspectionId,
  });

  const setCarPicturesProgress = useProgress();
  const updateProgress = React.useCallback(() => {
    setCarPicturesProgress(mapDataToFormValues(data?.inspection));
  }, [data, setCarPicturesProgress]);

  React.useEffect(() => {
    setCarPicturesProgress(mapDataToFormValues(data?.inspection));
  }, [data, setCarPicturesProgress]);

  return updateProgress;
}

export { useCarPicturesProgress };
